import TrustedUserModerationIcon from '@material-ui/icons/RecentActors';

import TrustedUserModerationList from './TrustedUserList';
import TrustedUserModerationEdit from './TrustedUserEdit';

export default {
  list: TrustedUserModerationList,
  edit: TrustedUserModerationEdit,
  icon: TrustedUserModerationIcon,
};

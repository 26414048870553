import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900],
    },
    '&:disabled': {
      backgroundColor: red[50],
    },
    margin: '0 10px',
  },
}));

const ErrorButton = (props: any) => {
  const classes = useStyles();

  return <Button variant="contained" {...props} classes={classes} />;
};

export default ErrorButton;

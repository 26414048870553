import React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import IceBreakerConfigForm from './IceBreakerConfigForm';

const ConfigTitle: FC<any> = ({ record }) =>
  record ? <span>IcebreakerConfig #{record.id}</span> : null;

const IceBreakerConfigEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<ConfigTitle />} {...props}>
      <IceBreakerConfigForm />
    </Edit>
  );
};

export default IceBreakerConfigEdit;

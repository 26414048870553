import React from 'react';
import { FC } from 'react';
import {
  Edit,
  ReferenceInput,
  required,
  EditProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { TextSource } from 'model-types';
import { textTranslationsActions } from 'consts';
import CopyClipboardInput from '../../../layout/input/CopyClipboardInput';

interface TextSourceProps {
  record?: TextSource;
}

const TextSourceTitle: FC<TextSourceProps> = ({ record }) =>
  record ? <span>Text Source #{record.id}</span> : null;

const TextSourceEdit: FC<EditProps> = (props) => {
  return (
    <Edit title={<TextSourceTitle />} {...props}>
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Category"
          source="category_id"
          reference="translations/text-categories"
          alwaysOn
          validate={[required()]}
        >
          <SelectInput
            sx={{ width: '175px' }}
            optionText={(choice) =>
              '-'.repeat(choice.level) + ' ' + choice.name
            }
          />
        </ReferenceInput>
        <TextInput source="key" />
        <CopyClipboardInput source="full_key" disabled={true} />
        <TextInput source="value" multiline style={{ width: '100%' }} />
        <SelectInput
          source="action"
          choices={textTranslationsActions}
          label="Do with translations"
          defaultValue="disapprove"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};
export default TextSourceEdit;

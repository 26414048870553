import React from 'react';
import PropTypes from 'prop-types';
import { FieldProps } from '../../types';

const IconField = ({ source, record }: FieldProps) => {
  return record && source && record[source] ? (
    <div style={{ height: '25px' }}>
      <img src={record[source]} style={{ height: '100%' }} alt={source} />
    </div>
  ) : null;
};
IconField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default IconField;

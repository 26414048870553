import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    margin: '0 10px',
  },
}));

const SuccessButton = (props: any) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      {...props}
      disabled={props?.disabled}
      classes={classes}
    />
  );
};

export default SuccessButton;

import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { Supply } from '../../../model-types';
import SupplyForm from './SupplyForm';

interface PartnerProps {
  record?: Supply;
}

const SupplyTitle: FC<PartnerProps> = ({ record }) =>
  record ? <span>Supply #{record.id}</span> : null;

const SupplyEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<SupplyTitle />} {...props}>
      <SupplyForm isEdit />
    </Edit>
  );
};

export default SupplyEdit;

import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import MyPagination from 'layout/Pagination';
import StatusField from './submodules/StatusField';
import TicketField from './submodules/TicketField';
import ReasonField from './submodules/ReasonField';
import LinkField from './submodules/LinkField';

const NotificationsList = (props: any) => {
  return (
    <List
      perPage={15}
      pagination={<MyPagination />}
      bulkActionButtons={false}
      sort={{ field: 'status', order: 'DESC' }}
      {...props}
    >
      <Datagrid optimized>
        <TextField source="id" sortable={false} align="left" />
        <LinkField
          source="user.id"
          label="UserId"
          sortable={false}
          align="left"
        />
        <TextField
          source="assignee.id"
          label="Assignee"
          sortable={false}
          align="left"
        />

        <TicketField sortable={false} label="Ticket" />

        <ReasonField source="reason" sortable={false} />
        <StatusField source="status" sortable={false} />

        <TextField
          source="user.username"
          label="Username"
          sortable={false}
          align="left"
        />
        <TextField
          source="user.country_name"
          label="Country"
          sortable={false}
          align="left"
        />
        <TextField
          source="user.host"
          label="Host"
          sortable={false}
          align="left"
        />

        <DateField
          source="updated_at"
          sortable={false}
          showTime
          locales="ru-RU"
        />
        <DateField
          source="created_at"
          sortable={false}
          showTime
          locales="ru-RU"
        />
      </Datagrid>
    </List>
  );
};

export default NotificationsList;

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  label: string;
  field: string;
  record: any;
}

const useStyles = makeStyles((theme) => ({
  span: {
    display: 'block',
    margin: '8px 0 0 0',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

const OtherDetailTextField: FC<Props> = ({ label, record, field }) => {
  const classes = useStyles();

  return (
    <>
      <span className={classes.span}>{label}</span>
      {record.other_details[field] !== undefined && (
        <span>
          {
            record.options[field].options.find(
              (option: any) => option.value === record.other_details[field],
            )?.label
          }
        </span>
      )}
    </>
  );
};

export default OtherDetailTextField;

import React from 'react';
import { ResourceComponentProps, Title } from 'react-admin';
import { Card, CardContent, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { httpClient } from 'httpClient';
import { AxiosResponse } from 'axios';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& h2': {
      margin: '60px 0 0',
    },
    '&:first-child h2': {
      marginTop: '0',
    },
  },
  enumName: {
    margin: '15px 0 5px',
  },
  enum: {
    lineHeight: 1.7,
    color: 'black',
    fontSize: '14px',
    fontFamily: 'monospace',
    '& span': {
      opacity: 0.7,
    },
  },
}));

type PhpEnumAll = {
  [key: string]: EnumModuleMap;
};
type EnumModuleMap = {
  [key: string]: PhpEnum;
};
type PhpEnum =
  | {
      [key: string]: number;
    }
  | Array<string>;

const PhpEnumList = (props: ResourceComponentProps) => {
  const [phpEnumAll, setPhpEnumAll] = useState<PhpEnumAll>({});
  const classes = useStyles();

  useEffect(getList, []);

  function getList() {
    httpClient.get('/docs/enums').then(fillList);
  }

  function fillList(response: AxiosResponse) {
    setPhpEnumAll(response.data);
  }

  return (
    <Card>
      <Title title="Enums" />
      <CardContent>
        {Object.entries(phpEnumAll).map(([module, enumModuleMap]) => (
          <Grid container spacing={1} className={classes.container}>
            <Grid item xs={12}>
              <h2>{module}</h2>
            </Grid>
            {Object.entries(enumModuleMap).map(([enumName, phpEnum]) => (
              <Grid item xs={4}>
                <h3 className={classes.enumName}>{enumName}</h3>
                <div className={classes.enum}>
                  {Array.isArray(phpEnum)
                    ? phpEnum.map((value) => (
                        <>
                          {value}
                          <br />
                        </>
                      ))
                    : Object.entries(phpEnum).map(([key, value]) => (
                        <>
                          {value} <span>→</span> {key}
                          <br />
                        </>
                      ))}
                </div>
              </Grid>
            ))}
          </Grid>
        ))}
      </CardContent>
    </Card>
  );
};
export default PhpEnumList;

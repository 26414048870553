import React from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';
import SoundToggle from './SoundToggle';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const CustomUserMenu = (props: any) => <UserMenu {...props}></UserMenu>;

const CustomAppBar = (props: any) => {
  const classes = useStyles();

  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <span className={classes.spacer} />
      <SoundToggle />
    </AppBar>
  );
};

export default CustomAppBar;

export const activityAction = [
  { id: 0, name: 'none' },
  { id: 1, name: 'session_random' },
  { id: 2, name: 'message' },
  { id: 3, name: 'sympathy' },
  { id: 4, name: 'visit' },
  { id: 5, name: 'ice_breaker' },
  { id: 6, name: 'recommended_user' },
  { id: 7, name: 'missed_message' },
  { id: 8, name: 'new_user_on_site' },
  { id: 9, name: 'popular_user' },
  { id: 10, name: 'favourite_user' },
  { id: 11, name: 'featured_user' },
  { id: 12, name: 'inmail_ice_breaker' },
  { id: 13, name: 'wizard_ice_breaker' },
  { id: 14, name: 'follow_up_ice_breaker' },
  { id: 15, name: 'online_dialog_ice_breaker' },
  { id: 16, name: 'mail_last_connection' },
  { id: 17, name: 'mail_last_profiles' },
  { id: 18, name: 'mail_bonus_credits' },
  { id: 105, name: 'ice_breaker_chain' },
];

export const activityType = [
  { id: 0, name: 'unknown' },
  { id: 1, name: 'session' },
  { id: 2, name: 'system' },
  { id: 3, name: 'trigger' },
  { id: 4, name: 'dynamic_trigger' },
  { id: 5, name: 'wizard_trigger' },
  { id: 6, name: 'offline' },
];

export const activityStatus = [
  { id: 3, name: 'failed' },
  { id: 4, name: 'success' },
];

export const activityStatusReason = [
  { id: 0, name: 'ok' },
  { id: 1, name: 'none_selected' },
  { id: 2, name: 'none_provided' },
  { id: 3, name: 'daily_limit_reached' },
  { id: 4, name: 'session_expired' },
  { id: 5, name: 'runtime_exception' },
  { id: 6, name: 'activity_paused' },
  { id: 7, name: 'activity_paused_for_user' },
  { id: 11, name: 'user_has_no_icebreaker' },
  { id: 12, name: 'empty_candidates_list' },
  { id: 13, name: 'no_profile_for_site' },
  { id: 14, name: 'no_old_dialogs' },
  { id: 15, name: 'last_message_is_not_icebreaker' },
  { id: 16, name: 'icebreaker_is_not_active' },
  { id: 17, name: 'dialog_already_exists' },
  { id: 18, name: 'filters_no_candidates' },
  { id: 19, name: 'filters_excluded_empty_list' },
  { id: 20, name: 'filters_is_test_empty_list' },
  { id: 21, name: 'filters_online_empty_list' },
  { id: 22, name: 'no_contacts_with_limits' },
  { id: 69, name: 'unknown' },
];

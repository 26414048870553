import React from 'react';
import { FC } from 'react';
import {
  Create,
  useRedirect,
  useNotify,
  ResourceComponentProps,
} from 'react-admin';
import NotificationsForm from './NotificationsForm';

const NotificationsCreate: FC<ResourceComponentProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Notifications created');
    redirect('list', props.basePath);
  };

  return (
    <Create title="Create Notifications" onSuccess={onSuccess} {...props}>
      <NotificationsForm />
    </Create>
  );
};

export default NotificationsCreate;

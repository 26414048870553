import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  NumberField,
  BooleanField,
  SelectField,
} from 'react-admin';
import CustomPagination from 'layout/Pagination';
import { supplyRank } from './field-choices';

const SupplyFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
  </Filter>
);

const SupplyList = (props: any) => {
  return (
    <List
      // filters={<SupplyFilter />}
      perPage={15}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField
          source="id"
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField source="name" textAlign="center" sortable={false} />
        <SelectField source="rank" choices={supplyRank} />
        <TextField source="site_ids" textAlign="center" sortable={false} />
        <BooleanField
          source="code_available"
          sortable={false}
          textAlign="center"
        />
        <NumberField
          source="coefficient"
          options={{ useGrouping: false }}
          sortable={false}
        />
        <BooleanField
          source="gift_available"
          sortable={false}
          textAlign="center"
        />
        <NumberField
          source="gift_coefficient"
          options={{ useGrouping: false }}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default SupplyList;

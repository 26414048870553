import React, { memo, useCallback, useState } from 'react';
import Toggle from 'react-toggle';

import { NotificationsActive } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'helper/localStorage';

const SOUND_NOTIFICATION_KEY = 'sound';

const SoundToggle = memo(() => {
  const [isSoundEnabled, setIsSoundEnabled] = useState(
    () => !!getLocalStorageItem(SOUND_NOTIFICATION_KEY),
  );

  const handleToggleSound = useCallback(() => {
    setIsSoundEnabled((prev) => {
      if (prev) removeLocalStorageItem(SOUND_NOTIFICATION_KEY);
      else setLocalStorageItem(SOUND_NOTIFICATION_KEY, '1');

      return !prev;
    });
  }, []);

  return (
    <Box display="flex" alignItems="center">
      <NotificationsActive />
      <Toggle checked={isSoundEnabled} onChange={handleToggleSound} />
    </Box>
  );
});

export default SoundToggle;

import React from 'react';
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  Filter,
  NumberInput,
  SelectInput,
  ImageField,
  SimpleList,
  ArrayField,
  BooleanField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { ModerationBtns } from 'component/common/ModerationBtns/ModerationBtns';
import CustomPagination from 'layout/Pagination';
import IceBreakerType from 'layout/IceBreakerType';

import { IceBreaker } from 'model-types';

import { IceBreakerBtns } from './submodules/IceBreakerBtns';
import { iceBreakerType } from './field-choices';
import { useEffect, useState } from 'react';
import { getArrayFromLinkedList } from '../../utils';
import BooleanFromNumberField from '../../layout/fields/BooleanFromNumberField';

const useStyles = makeStyles(() => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
  imageCell: {
    maxWidth: '170px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
    gridGap: '5px',
  },
  imageCellItem: {
    width: '100%',
    margin: 0,
    objectFit: 'contain',
  },
  message: {
    minWidth: '320px',
    width: '320px',
  },
  childrens: {
    minWidth: '160px',
    width: '160px',
  },
}));

const IceBreakerFilter = (props: any) => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    setSuppliesSelectChoses(suppliesArray);
  };

  useEffect(() => {
    fetchSupplies();
  }, []);

  return (
    <Filter {...props} style={{ maxWidth: '1000px' }}>
      <NumberInput source="id" label="Id" alwaysOn style={{ width: '120px' }} />

      {!!suppliesSelectChoses && (
        <SelectInput
          alwaysOn
          label="Supply"
          source="supply_id"
          choices={suppliesSelectChoses}
          style={{ minWidth: '120px' }}
        />
      )}

      <NumberInput
        source="trusted_user_id"
        alwaysOn
        label="TU id"
        style={{ width: '120px' }}
      />
      <SelectInput
        label={'Type'}
        source="type"
        choices={iceBreakerType}
        alwaysOn
        style={{ width: '120px' }}
      />
    </Filter>
  );
};

const IceBreakerList = (props: IceBreaker) => {
  const classes = useStyles();

  return (
    <List
      perPage={15}
      filters={<IceBreakerFilter />}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid
        optimized
        rowStyle={(row) => {
          if (row.moderation_status === 3) {
            return { background: 'rgb(226, 222, 222)' };
          }
        }}
      >
        <NumberField
          source="id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          sortable={false}
        />
        <TextField
          source="trusted_user.supply.name"
          label="Supply"
          sortable={false}
        />
        <NumberField
          label="TU id"
          source="trusted_user.id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          sortable={false}
        />
        <NumberField
          label="Carthage TU id"
          source="trusted_user.external_id"
          cellClassName={classes.idCell}
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField
          source="message"
          sortable={false}
          cellClassName={classes.message}
        />
        <ArrayField
          source="childrens"
          label="Childrens"
          sortable={false}
          cellClassName={classes.message}
        >
          <SimpleList
            primaryText={(record) => record.message}
            secondaryText={(record) =>
              `Delay: ${record.delay}, Sent: ${record.sent_count}, Read: ${record.read_count}`
            }
            linkType={false}
          />
        </ArrayField>
        <ImageField
          source="media"
          src="big_url"
          classes={{
            list: classes.imageCell,
            image: classes.imageCellItem,
          }}
        />

        <NumberField source="sent_count" sortable={false} />
        <NumberField source="read_count" sortable={false} />
        <FunctionField
          label="Type"
          render={(record?: Record<string, any>) => (
            <IceBreakerType record={record} />
          )}
        />
        <DateField source="created_at" locales="UK" showTime sortable={false} />
        <BooleanFromNumberField source="is_deleted" />
        <IceBreakerBtns />
        <ModerationBtns />
      </Datagrid>
    </List>
  );
};

export default IceBreakerList;

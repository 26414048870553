import { FC, useCallback, useEffect, useState } from 'react';
import { Select , FormControl, InputLabel, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formControl: {
    minWidth: 150,
  },
  select: {
    padding:' 0 10px'
  }
});

interface Props {
  currentId: string;
  usedIdList: string[];
  supplyIdList: number[];
  setSupplyItemId: (supplyId: string) => void
}

export const SupplyIdSelect: FC<Props> = ({ currentId, usedIdList, supplyIdList, setSupplyItemId}) => {
  const [supplyId, setSupplyId] = useState(''); 

  const classes = useStyles();
  
  const handleChangeSupplyId = useCallback((event) => {       
    if(usedIdList.includes(event.target.value)) {
      alert('This id is not available');
      
      return;
    }

    setSupplyId(event.target.value);
    setSupplyItemId(event.target.value);
  }, [usedIdList]);

  useEffect(() => {
    if(!supplyId && currentId) {
      setSupplyId(currentId)
    }
  }, [currentId, supplyId]);

  return ( 
    <FormControl className={classes.formControl}>
      <InputLabel id="placement-id's-label">Supply id:&nbsp;</InputLabel>
      <Select
        labelId="placement-id's-label"
        id="placement-id's-select"
        value={supplyId}
        className={classes.select}
        onChange={handleChangeSupplyId}
      >
        {supplyIdList.map(id => <MenuItem value={id} key={id}>{id}</MenuItem>)}
      </Select>
    </FormControl>
  );
};



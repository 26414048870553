import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  Filter,
  NumberInput,
  TextInput,
  ResourceComponentProps,
} from 'react-admin';

const PartnerNetworkFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
  </Filter>
);

const PartnerNetworkList = (props: ResourceComponentProps) => {
  return (
    <List
      {...props}
      filters={<PartnerNetworkFilter />}
      perPage={100}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid optimized rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="created_at" locales="ru-RU" showTime />
        <DateField source="updated_at" locales="ru-RU" showTime />
      </Datagrid>
    </List>
  );
};

export default PartnerNetworkList;

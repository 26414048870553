import {
  fetchUtils,
  UpdateParams,
  CreateParams,
  GetListParams,
  GetOneParams,
  GetOneResult,
  UpdateResult,
  CreateResult,
  HttpError,
} from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import Cookies from 'js-cookie';

import { getApiUrl, getEnvByHostname } from './utils';
import { GetListResult } from 'react-admin';
import queryString from 'query-string';

const currentHostname = window.location.hostname;
const env = getEnvByHostname(currentHostname);
const baseApiUrl = getApiUrl(currentHostname, env);

const simpleRestClient = async (url: any, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
    });
  }
  if (options.headers instanceof Headers) {
    let csrfToken = Cookies.get('XSRF-TOKEN');
    if (csrfToken) {
      options.headers.append('X-Csrf-Token', csrfToken);
    }
  }
  options.credentials = 'include';

  return fetchUtils.fetchJson(url, options).catch((err: HttpError) => {
    let message = err.body;
    if (err.status === 422) {
      message = getValidationError(err.body);
    }
    return Promise.reject(new HttpError(message, err.status, err.body));
  });
};

const getValidationError = (body: any): any => {
  if (!body) {
    return body;
  }
  if (body.error) {
    return body.error;
  }
  let error = '';
  for (let key in body) {
    if (Array.isArray(body[key])) {
      error += body[key].join('. ');
    } else {
      error += body[key];
    }
    error += '. ';
  }
  return error;
};

const baseProvider = simpleRestProvider(
  `${baseApiUrl}/admin`,
  simpleRestClient,
  'X-Total-Count',
);

const restProvider = {
  ...baseProvider,

  getList: async (
    resource: string,
    params: GetListParams,
  ): Promise<GetListResult<any>> => {
    if (
      resource === 'trusted-user' ||
      resource === 'trusted-user/moderation' ||
      resource === 'trusted-user/re-moderation' ||
      resource === 'support/ticket' ||
      resource === 'ice-breaker/moderation' ||
      resource === 'ice-breaker/all' ||
      resource === 'ice-breaker/config' ||
      resource === 'supply' ||
      resource === 'supply-agent' ||
      resource === 'supply-notification' ||
      resource === 'activity'
    ) {
      const { page } = params.pagination;
      const parsedQuery = queryString.parseUrl(
        `${baseApiUrl}/admin/${resource}`,
      );
      parsedQuery.query.page = String(page);
      if (params.filter) {
        parsedQuery.query.filter = JSON.stringify(params.filter);
      }
      const updatedUrl = queryString.stringifyUrl(parsedQuery);

      return fetchUtils
        .fetchJson(updatedUrl, { credentials: 'include' })
        .then(({ json }) => {
          return { data: json.data, total: json.meta.total };
        });
    }
    return baseProvider.getList(resource, params);
  },
  getOne: async (
    resource: string,
    params: GetOneParams,
  ): Promise<GetOneResult<any>> => {
    let finalResource = resource;

    if (finalResource === 'trusted-user/re-moderation') {
      finalResource = 'trusted-user/moderation';
    }

    if (
      finalResource === 'trusted-user' ||
      finalResource === 'trusted-user/moderation' ||
      finalResource === 'trusted-user/re-moderation' ||
      finalResource === 'ice-breaker/moderation' ||
      finalResource === 'ice-breaker/config' ||
      finalResource === 'supply' ||
      finalResource === 'supply-agent' ||
      finalResource === 'supply-notification'
    ) {
      return baseProvider
        .getOne(finalResource, params)
        .then(({ data }: any) => {
          if (
            finalResource === 'trusted-user' ||
            finalResource === 'trusted-user/moderation' ||
            finalResource === 'trusted-user/re-moderation'
          ) {
            data.data.options = data.options;
          }
          return { data: data.data };
        });
    }

    return baseProvider.getOne(finalResource, params);
  },

  update: async (
    resource: string,
    params: UpdateParams,
  ): Promise<UpdateResult<any>> => {
    if (resource === 'support/ticket') {
      return baseProvider.update(resource, params).then(({ data }: any) => {
        return { data: { ...params.previousData, ...data.data } };
      });
    }

    if (resource === 'supply' || resource === 'supply-agent')
      return baseProvider.update(resource, params).then(({ data }: any) => {
        return { data: data.data };
      });

    if (!params.data.pictures) {
      return baseProvider.update(resource, params);
    }

    const pictures = params.data.pictures;

    return Promise.all(
      Object.keys(pictures).map(function (key: any) {
        const file = pictures[key];
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve([key, reader.result]);
          reader.onerror = reject;

          reader.readAsDataURL(file.rawFile);
        });
      }),
    ).then((transformedNewPictures) =>
      baseProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
        },
      }),
    );
  },
  create: async (
    resource: string,
    params: CreateParams,
  ): Promise<CreateResult<any>> => {
    if (!params.data.pictures) {
      return baseProvider.create(resource, params);
    }

    const pictures = params.data.pictures;

    return Promise.all(
      Object.keys(pictures).map(function (key: any) {
        const file = pictures[key];
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve([key, reader.result]);
          reader.onerror = reject;

          reader.readAsDataURL(file.rawFile);
        });
      }),
    ).then((transformedNewPictures) =>
      baseProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          pictures: [...transformedNewPictures],
        },
      }),
    );
  },
};

export default restProvider;

import React from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  Record,
  NumberField,
} from 'react-admin';
import MyPagination from 'layout/Pagination';
import TypeField from './submodules/TypeField';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Supply } from 'model-types';

const useStyles = makeStyles((theme) => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
}));

const NotificationsList = (props: any) => {
  const classes = useStyles();

  const changeRowStyle = (record: Record) => {
    return {
      cursor: record.deleted_at ? 'auto' : 'pointer',
      backgroundColor: record.deleted_at ? '#e2dede' : 'inherit',
    };
  };

  const getSuppliesChip = (record: Record | undefined) => {
    return record?.supplies?.map((supply: Supply) => (
      <Chip
        size="small"
        key={supply.id}
        label={supply.name}
        style={{ marginBottom: '5px' }}
      />
    ));
  };

  return (
    <List perPage={15} pagination={<MyPagination />} {...props}>
      <Datagrid
        optimized
        rowStyle={changeRowStyle}
        isRowSelectable={(row: Record) => !row.deleted_at}
      >
        <NumberField
          source="id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          sortable={false}
        />
        <TypeField source="type" sortable={false} />
        <TextField
          source="message"
          style={{ maxHeight: '110px', display: 'block', overflowY: 'scroll' }}
          sortable={false}
        />
        <div
          style={{
            maxHeight: '115px',
            display: 'block',
            overflowY: 'scroll',
          }}
          label="Supplies"
        >
          <FunctionField
            label="supplies"
            render={getSuppliesChip}
            sortable={false}
          />
        </div>
        <DateField source="deleted_at" locales="UK" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default NotificationsList;

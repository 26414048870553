import React, { useState } from 'react';
import { useNotify, useRecordContext } from 'react-admin';
import { httpClient } from 'httpClient';

import { makeStyles } from '@material-ui/core/styles';
import { IceBreaker, IceBreakerModerationStatus } from 'model-types';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';

const useStyles = makeStyles((theme) => ({
  yellowBtn: {
    minWidth: '130px',
    margin: '4px',
  },
}));

export const IceBreakerBtns = () => {
  const record = useRecordContext<IceBreaker>();

  const classes = useStyles();
  const notify = useNotify();

  const [activeBtn, setActiveBtn] = useState(record.is_active);

  const handleActivate = () => {
    httpClient
      .post(`/ice-breaker/${record.id}/activate`)
      .then(() => setActiveBtn(1))
      .catch((err) => notify(err?.message));
  };
  const handleDeactivate = () => {
    httpClient
      .post(`/ice-breaker/${record.id}/deactivate`)
      .then(() => setActiveBtn(0))
      .catch((err) => notify(err?.message));
  };

  if (
    !record ||
    record.moderation_status === IceBreakerModerationStatus.Moderation ||
    record.moderation_status === IceBreakerModerationStatus.Decline
  )
    return null;

  return (
    <ToggleActivateButton
      disabled={!!record.is_deleted}
      isActivate={!activeBtn}
      handleActivate={handleActivate}
      handleDeactivate={handleDeactivate}
    />
  );
};

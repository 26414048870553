import { FC, useCallback, useEffect, useState } from 'react';
import { Divider, Button , Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import { SupplyItem } from './SupplyItem';
import { SupplyItemType } from './WizardEditConfigs';

const useStyles = makeStyles({
  listItemContent: {
    padding: '10px 0'
  },
  placementBlockInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center'
  },
  placementSupplyIds: {
    marginBottom: '10px'
  },
  button: {
    backgroundColor: "#626262",
  },
});

interface PlacementItems {
  [key: string]: SupplyItemType
}
interface Props{
  placementItems: PlacementItems[];
  placementPosition: number;
  index: number;
  supplyIdList: number[];
  onAddSupplyItem: (placementIndex: number) => void;
  onRemoveSupplyItem: (updatedPlacement: any, index: number) => void;
  setPlacementSupplyItem: (placementItem: SupplyItemType, index: number, supplyItemIndex: number)=> void;
}

export const PlacementItem: FC<Props> = ({ index, placementPosition, placementItems, supplyIdList, setPlacementSupplyItem, onAddSupplyItem, onRemoveSupplyItem}) => {
  const [addedItem, setAddedItem] = useState<string[]>([]);

  const classes = useStyles();

  const handleSetSupplyItem = useCallback(({supply_id, weight}, supplyItemIndex) => {    
    setPlacementSupplyItem({supply_id, weight}, index, supplyItemIndex);
    setAddedItem(state => {
      state[supplyItemIndex] = supply_id;
      return state
    })
  }, [setPlacementSupplyItem]);

  const handleAddSupply = useCallback(() => {
    onAddSupplyItem(index);
  }, []);

  const handleRemoveItem = useCallback(supplyItemIndex => {   
    const filteredPlacements = placementItems
    .filter((supplyItem, supplyIndex) => supplyItemIndex !== supplyIndex && supplyItem)
    .map((item, index) => ({ [String(index)]: Object.values(item)[0] }));

    onRemoveSupplyItem(filteredPlacements, index);
    setAddedItem(filteredPlacements.map((item, index) => item[index].supply_id))
  }, [placementItems]);

  useEffect(() => {
    const idList = placementItems.map((item, index) => item[index].supply_id)
    
    setAddedItem(idList)
  }, [placementItems]);

  return ( 
    <li>
      <Grid container alignItems="center" spacing={5} className={classes.listItemContent}>
        <Grid item xs={2} className={classes.placementBlockInfo}>
          Placement {placementPosition}
          <Button 
            variant="contained" 
            type="button"
            color="primary" 
            size="small" 
            className={classes.button}
            onClick={handleAddSupply}
          >
            Add supply id 
          </Button>
        </Grid>
        <Grid item xs={10}>
          <ul className={classes.placementSupplyIds}>
              {placementItems.map((item, index) => {     
                return <SupplyItem 
                  id={item[index].supply_id} 
                  key={item[index].supply_id} 
                  weight={item[index].weight}
                  supplyItemIndex={index}
                  isDeleteButtonDisabled={placementItems.length === 1} 
                  usedIdList={addedItem}
                  supplyIdList={supplyIdList}
                  setSupplyItem={handleSetSupplyItem}
                  deleteSupplyItem={handleRemoveItem}
                />
              })}
          </ul>
        </Grid>
      </Grid>
      <Divider />
    </li>
  );
};



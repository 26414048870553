import React from 'react';
import { useRecordContext } from 'react-admin';
import { Chip } from '@material-ui/core';
import { SupportTicket } from 'types/SupportTicket';
import { SupportTicketStatus } from 'types/enums/SupportTicket';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  assigned: {
    background: '#c6c6c6',
  },
  new: {
    background: '#4caf50',
  },
}));

const StatusField = (props: any) => {
  const record = useRecordContext<SupportTicket>();
  const classes = useStyles();

  if (!record) {
    return null;
  }

  if (record.status === SupportTicketStatus.Assigned)
    return <Chip label="Assigned" className={classes.assigned} />;

  return <Chip label="New" className={classes.new} />;
};

export default StatusField;

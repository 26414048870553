import { makeStyles } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClick?: () => void;
  isLoader?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: yellow[700],
    '&:hover': {
      backgroundColor: yellow[800],
    },
    '&:disabled': {
      backgroundColor: yellow[50],
    },
    margin: '0 10px',
  },
}));

const YellowButton = ({
  children,
  onClick,
  className,
  isLoader = false,
}: Props) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={cx(classes.root, className)}
      onClick={onClick}
    >
      {isLoader ? <CircularProgress size="20px" /> : children}
    </Button>
  );
};

export default YellowButton;

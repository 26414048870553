import { useMemo, useState } from 'react';
import { httpClient } from 'httpClient';
import { useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SiteIdChoices } from 'api/PidvalApi';

interface Props {
  isOpen: boolean;
  userId: number;
  selectedIds: number[];
  newSelectedIds: number[];
  siteIdChoices: SiteIdChoices[];
  onClose: () => void;
  onChangeSelectedIds: (args: number[]) => void;
}

export const ConfirmationDialog = ({
  isOpen,
  userId,
  selectedIds,
  newSelectedIds,
  siteIdChoices,
  onClose,
  onChangeSelectedIds,
}: Props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const newMirror = useMemo(() => {
    return siteIdChoices.find(
      (item) => newSelectedIds[newSelectedIds.length - 1] === item.id,
    )?.name;
  }, [siteIdChoices, newSelectedIds]);

  const removedMirror = useMemo(() => {
    const removedMirror = selectedIds.find(
      (item) => !newSelectedIds.includes(item),
    );
    return siteIdChoices.find((item) => item.id === removedMirror)?.name;
  }, [siteIdChoices, newSelectedIds]);

  const notify = useNotify();

  const handleConfirm = () => {
    setIsButtonDisabled(true);

    httpClient
      .post(
        `/trusted-user/${userId}/change-site`,
        {
          site_ids: newSelectedIds,
        },
        { timeout: 30000 },
      )
      .then(() => {
        onChangeSelectedIds(newSelectedIds);

        notify("Available site id's list was updated", 'success');
      })
      .catch((error) => {
        notify(
          error.message || 'Something went wrong try change again',
          'error',
        );
      })
      .finally(() => {
        setIsButtonDisabled(false);
        onClose();
      });
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">
        Confirm your action
      </DialogTitle>
      <DialogContent>
        {selectedIds?.length < newSelectedIds.length &&
          `You confirm to duplicate(add) this profile on ${newMirror}.`}
        {!!newSelectedIds.length &&
          selectedIds?.length > newSelectedIds.length &&
          `You confirm to deactivate this profile on ${removedMirror}.`}
        {!newSelectedIds.length &&
          'You cannot deactivate, the profile must have at least one active site.'}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {!!newSelectedIds.length && (
          <Button
            onClick={handleConfirm}
            color="primary"
            disabled={isButtonDisabled}
          >
            Confirm
            {isButtonDisabled && (
              <div style={{ position: 'absolute', top: '5px', left: '25px' }}>
                <CircularProgress color="secondary" size="30px" />
              </div>
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

import NotificationsIcon from '@material-ui/icons/NotificationsActive';

import NotificationsList from './NotificationsList';
import NotificationsEdit from './NotificationsEdit';
import NotificationsCreate from './NotificationsCreate';

export default {
  list: NotificationsList,
  edit: NotificationsEdit,
  create: NotificationsCreate,
  icon: NotificationsIcon,
};

import React from 'react';
import { Route } from 'react-router-dom';
import UserPhotoModeration from './pages/moderation/photo/UserPhotoModeration';
import ActivityByUser from './pages/activity/ActivityByUser';
import { WizardEditConfigs } from 'pages/wizard/config/WizardEditConfigs';

export default [
  <Route
    exact
    path="/photo/moderation/:initPhotoId"
    component={UserPhotoModeration}
  />,
  <Route exact path="/activity/user/:userId" component={ActivityByUser} />,
  <Route exact path="/wizard-finder-config" component={WizardEditConfigs} />,
];

import TrustedUserModerationIcon from '@material-ui/icons/RecentActors';

import TrustedUserModerationList from './TrustedUserModerationList';
import TrustedUserModerationEdit from './TrustedUserModerationEdit';

export default {
  list: TrustedUserModerationList,
  edit: TrustedUserModerationEdit,
  icon: TrustedUserModerationIcon,
};

export const getUTCDateDifferenceInMinutes = (UTCData: string): number => {
  const utcDate = new Date(UTCData);

  const newData = new Date();
  const currentUTCDate = new Date(
    newData.getTime() + newData.getTimezoneOffset() * 60000,
  );

  const milliseconds = utcDate.getTime() - currentUTCDate.getTime();
  const minutes = Math.floor(milliseconds / 60000);

  return minutes;
};

import React, { FC } from 'react';
import Zoom from 'react-medium-image-zoom';

import { ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MediaModerationStatus from './MediaModerationStatus';

import { MediaType } from 'types/mediaType';
import { TrustedUserMedia } from 'types/trustedUser/trustedUserModeration';

import 'react-medium-image-zoom/dist/styles.css';

interface Props {
  media: TrustedUserMedia[];
}

const useStyles = makeStyles((theme) => ({
  imageList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const MediaList: FC<Props> = ({ media }) => {
  const classes = useStyles();

  if (!media) {
    return null;
  }

  if (media.length === 0) {
    return <p>No media</p>;
  }

  return (
    <>
      <div className={classes.imageList}>
        {media.map((item, index) => (
          <ImageListItem
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '5px',
            }}
          >
            {item.type === MediaType.Video ||
            item.type === MediaType.PrivateLibraryVideo ? (
              <video
                src={item.file_url}
                style={{ width: '300px', maxHeight: '80%' }}
                controls
              />
            ) : (
              <Zoom>
                <img
                  src={item.file_url}
                  style={{
                    height: '300px',
                    maxWidth: '100%',
                    minWidth: '150px',
                    objectFit: 'contain',
                  }}
                  loading="lazy"
                />
              </Zoom>
            )}
            <MediaModerationStatus
              item={item}
              isEroticVisible={
                item.type === MediaType.PrivateLibraryPhoto ||
                item.type === MediaType.PrivateLibraryVideo
              }
            />
          </ImageListItem>
        ))}
      </div>
    </>
  );
};

export default MediaList;

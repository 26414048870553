import React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  ReferenceInput,
  ResourceComponentProps,
} from 'react-admin';
import TreeSelectInput from '../../../layout/input/TreeSelectInput';
import { makeStyles } from '@material-ui/core/styles';
import queryString, { ParsedQuery } from 'query-string';

const useStyles = makeStyles((theme) => ({
  textSourceKey: {
    width: '25%',
    float: 'left',
    marginRight: '20px',
  },
  textSourceValue: {
    width: '60%',
    float: 'left',
  },
}));

const TextSourceCreate: FC<ResourceComponentProps> = (props) => {
  const classes = useStyles();
  const queryParams: ParsedQuery = props.location?.search
    ? queryString.parse(props.location?.search)
    : {};

  return (
    <Create title="Create Text Sources" {...props}>
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Category"
          source="category_id"
          reference="translations/text-categories-select"
          alwaysOn
          validate={[required()]}
          defaultValue={queryParams?.category_id}
        >
          <TreeSelectInput optionText="name" checkTree={false} />
        </ReferenceInput>

        <ArrayInput
          source="text_sources"
          defaultValue={[{ key: '', value: '' }]}
        >
          <SimpleFormIterator>
            <TextInput
              source="key"
              validate={[required()]}
              label="Key"
              className={classes.textSourceKey}
            />
            <TextInput
              source="value"
              validate={[required()]}
              label="Value"
              multiline
              className={classes.textSourceValue}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
export default TextSourceCreate;

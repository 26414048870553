import React from 'react';
import { FC } from 'react';
import {
  Edit,
  ResourceComponentPropsWithId,
  SimpleForm,
  TextInput,
  useRefresh,
  AutocompleteArrayInput,
  NumberInput,
} from 'react-admin';
import { SystemSetting } from 'model-types';
import { httpClient } from 'httpClient';
import SuccessButton from 'layout/buttons/SuccessButton';
import { getCountryNameChoices } from 'countries';

interface SystemSettingsProps {
  record?: SystemSetting;
}

const SystemSettingsTitle: FC<SystemSettingsProps> = ({ record }) =>
  record ? <span>System setting [{record.id}]</span> : null;

const SystemSettingsEdit: FC<ResourceComponentPropsWithId> = (props) => {
  const refresh = useRefresh();

  const reset = () => {
    httpClient.post('/system-settings/' + props.id + '/reset').then(() => {
      refresh();
    });
  };

  return (
    <Edit title={<SystemSettingsTitle />} {...props}>
      <SimpleForm redirect="list">
        <TextInput disabled source="id" label="Key" />
        {props.id === 'api_buying_allow_countries' ? (
          <AutocompleteArrayInput
            source="value"
            choices={getCountryNameChoices()}
            style={{ width: '100%' }}
          />
        ) : (
          <NumberInput source="value" label="Value" />
        )}
        <SuccessButton onClick={reset}>Reset to default</SuccessButton>
      </SimpleForm>
    </Edit>
  );
};

export default SystemSettingsEdit;

import React, { FC, ChangeEvent, useState, useMemo, useEffect } from 'react';
import { Input, Popper, Paper } from '@material-ui/core';
import { TrustedUserModerationStatuses } from 'model-types';
import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import { useNotify } from 'react-admin';

interface Props {
  tabNumber: number;
  moderationStatuses: TrustedUserModerationStatuses;
  setModerationStatuses: (
    moderationStatuses: TrustedUserModerationStatuses,
  ) => void;
}

const DEFAULT_DECLINES_REASON = {
  document_decline_reason: '',
  other_detail_decline_reason: '',
};

const DeclinePopup: FC<Props> = ({
  tabNumber,
  moderationStatuses,
  setModerationStatuses,
}) => {
  const notify = useNotify();

  const [anchorEl, setAnchorEl] = useState(null);
  const [declineReasonInput, setDeclineReasonInput] = useState(
    DEFAULT_DECLINES_REASON,
  );

  const declineKey = useMemo(
    () =>
      tabNumber === 0
        ? 'document_decline_reason'
        : 'other_detail_decline_reason',
    [tabNumber],
  );

  const handleSaveDeclineReason = () => {
    setModerationStatuses({
      ...moderationStatuses,
      [declineKey]: declineReasonInput[declineKey],
    });

    setAnchorEl(null);

    notify('Decline reason saved');
  };

  const handleOpenDeclinePopupBtn = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDeclineReasonInput((prev) => {
      return { ...prev, [declineKey]: event.target.value };
    });
  };

  useEffect(() => {
    if (moderationStatuses?.[declineKey]) {
      setDeclineReasonInput({
        document_decline_reason: moderationStatuses.document_decline_reason,
        other_detail_decline_reason:
          moderationStatuses.other_detail_decline_reason,
      });
    }
  }, []);

  return (
    <>
      <ErrorButton onClick={handleOpenDeclinePopupBtn}>Decline</ErrorButton>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disablePortal={true}
        placement="top"
        style={{ zIndex: '2' }}
        transition
      >
        <Paper
          style={{
            backgroundColor: '#fff',
            padding: '10px',
            width: '300px',
            height: '210px',
            maxHeight: '200px',
            overflowY: 'scroll',
          }}
        >
          <Input
            placeholder="Decline reason"
            multiline
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              width: '100%',
              padding: '5px',
              overflowY: 'scroll',
            }}
            value={declineReasonInput[declineKey]}
            onChange={handleInputChange}
          />
          <SuccessButton
            style={{ margin: '10px 0 0', backgroundColor: '#4f3cc9' }}
            onClick={handleSaveDeclineReason}
          >
            Save
          </SuccessButton>
        </Paper>
      </Popper>
    </>
  );
};

export default DeclinePopup;

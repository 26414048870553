import { Grid } from '@material-ui/core';

const ActivityQueueTable = (props: any) => {
  const { items } = { ...props };
  return (
    <>
      {items.map((item) => {
        return (
          <Grid container spacing={0} key={item.id}>
            <Grid item xs={2}>
              ID: {item.id}
            </Grid>
            <Grid item xs={2}>
              Type: {item.type}
            </Grid>
            <Grid item xs={3}>
              Action: {item.action}
            </Grid>
            <Grid item xs={5}>
              Run at: {item.run_at}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ActivityQueueTable;

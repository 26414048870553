import React, { FC } from 'react';
import Zoom from 'react-medium-image-zoom';

import { ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SuccessButton from 'layout/buttons/SuccessButton';
import ErrorButton from 'layout/buttons/ErrorButton';
import MediaModerationBtn from './MediaModerationBtn';

import { MediaType } from 'types/mediaType';
import { TrustedUserMedia } from 'types/trustedUser/trustedUserModeration';

import 'react-medium-image-zoom/dist/styles.css';

interface Props {
  media: TrustedUserMedia[];
  setMedia: (
    newValue:
      | TrustedUserMedia[]
      | ((prev: TrustedUserMedia[]) => TrustedUserMedia[]),
  ) => void;
}

const useStyles = makeStyles((theme) => ({
  imageList: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const MediaList: FC<Props> = ({ media, setMedia }) => {
  if (!media) {
    return null;
  }

  const classes = useStyles();

  const handleModerateMedia = ({
    id,
    statusId,
    isErotic,
  }: {
    id: number;
    statusId?: number;
    isErotic?: boolean;
  }) => {
    setMedia((prev: TrustedUserMedia[]) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            is_erotic: isErotic !== undefined ? isErotic : item.is_erotic,
            status_id: statusId !== undefined ? statusId : item.status_id,
          };
        }
        return item;
      }),
    );
  };

  const handleApproveAllBtn = () => {
    setMedia(
      media.map((item) => ({
        ...item,
        status_id: 1,
      })),
    );
  };
  const handleDeclineAllBtn = () => {
    setMedia(
      media.map((item) => ({
        ...item,
        status_id: 3,
      })),
    );
  };

  if (media.length === 0) {
    return <p>No media for moderation</p>;
  }

  return (
    <>
      <SuccessButton
        style={{ marginBottom: '5px' }}
        onClick={handleApproveAllBtn}
      >
        Approve all
      </SuccessButton>
      <ErrorButton
        style={{ marginBottom: '5px' }}
        onClick={handleDeclineAllBtn}
      >
        Decline all
      </ErrorButton>
      <div className={classes.imageList}>
        {media.map((item, index) => (
          <ImageListItem
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '5px',
            }}
          >
            {item.type === MediaType.Video ||
            item.type === MediaType.PrivateLibraryVideo ? (
              <video
                src={item.file_url}
                style={{ width: '300px', maxHeight: '80%' }}
                controls
              />
            ) : (
              <Zoom>
                <img
                  src={item.file_url}
                  style={{
                    height: '300px',
                    maxWidth: '100%',
                    minWidth: '150px',
                    objectFit: 'contain',
                  }}
                  loading="lazy"
                />
              </Zoom>
            )}
            <MediaModerationBtn
              item={item}
              handleModerateMedia={handleModerateMedia}
              isEroticCheckboxActive={
                item.type === MediaType.PrivateLibraryPhoto ||
                item.type === MediaType.PrivateLibraryVideo
              }
            />
          </ImageListItem>
        ))}
      </div>
    </>
  );
};

export default MediaList;

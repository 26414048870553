import React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { SupplyNotification } from '../../../model-types';
import NotificationsForm from './NotificationsForm';

interface PartnerProps {
  record?: SupplyNotification;
}

const NotificationsTitle: FC<PartnerProps> = ({ record }) =>
  record ? <span> #{record.id}</span> : null;

const NotificationsEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<NotificationsTitle />} {...props}>
      <NotificationsForm isEdit />
    </Edit>
  );
};

export default NotificationsEdit;

import React from 'react';
import { TextField } from 'react-admin';

const ShortTextField = (props: any) => {
  return props.record ? (
    <>
      <TextField
        style={{ maxHeight: '100px', display: 'block', overflow: 'hidden' }}
        {...props}
      />
    </>
  ) : (
    <></>
  );
};
export default ShortTextField;

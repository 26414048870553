import TextSourceIcon from '@material-ui/icons/TextFields';
import TextSourceList from './TextSourceList';
import TextSourceEdit from './TextSourceEdit';
import TextSourceCreate from './TextSourceCreate';

export default {
  list: TextSourceList,
  edit: TextSourceEdit,
  create: TextSourceCreate,
  icon: TextSourceIcon,
};

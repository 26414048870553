import axios from 'axios';

import { getApiUrl, getEnvByHostname } from './utils';

const currentHostname = window.location.hostname;
const env = getEnvByHostname(currentHostname);
const baseApiUrl = getApiUrl(currentHostname, env);

export const httpClient = axios.create({
  baseURL: `${baseApiUrl}/admin`,
  timeout: 15000,
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-Csrf-Token',
});

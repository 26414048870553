export const lightTheme = {
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaLayout: {
      content: {
        marginTop: '10px',
      },
    },
    RaSidebar: {
      fixed: {
        marginTop: '5px',
      },
    },
    RaMenuItemLink: {
      root: {
        // borderLeft: '3px solid #fff',
      },
      active: {
        // borderLeft: '3px solid #4f3cc9',
      },
    },
    RaBooleanField: {
      root: {
        justifyContent: 'center',
      },
    },
    MuiTabs: {
      root: {
        minHeight: '60px',
      },
    },
    MuiTab: {
      root: {
        minHeight: '60px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#4f3cc9',
        boxShadow: 'none',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
    MuiTypography: {
      body2: {
        fontSize: 'initial',
        letterSpacing: 'initial',
      },
    },
    MuiImageListItemBar: {
      root: {
        transform: 'translateY(-4px)',
      },
      actionIcon: {
        width: '100%',
      },
      titleWrap: {
        display: 'none',
      },
    },
  },
};

import React, { useEffect, useState } from 'react';
import { Toolbar, useRefresh } from 'react-admin';
import { useHistory, useLocation } from 'react-router';

import SuccessButton from 'layout/buttons/SuccessButton';

import DeclinePopup from './DeclinePopup';
import SubmitBtn from './SubmitBtn';
import YellowButton from 'layout/buttons/YellowButton';
import { httpClient } from 'httpClient';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import { GET_TUB_NUMBER_REG_EXP, TABS, TAB_DECLINE_FIELD } from '../constants';

export const CustomToolbar = ({
  record,
  moderationStatuses,
  setModerationStatuses,
  media,
}: any) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const refresh = useRefresh();

  const [tabNumber, setTubNumber] = useState(0);
  const [toggleTestStatusLoader, setToggleTestStatusLoader] = useState(false);

  const openNextTab = () => {
    const newUrl = tabNumber
      ? pathname.replace(GET_TUB_NUMBER_REG_EXP, String(tabNumber + 1))
      : pathname + '/1';

    history.push(`${newUrl}${search}`);
  };

  const openPrevTab = () => {
    const newUrl =
      tabNumber > 1
        ? pathname.replace(GET_TUB_NUMBER_REG_EXP, String(tabNumber - 1))
        : pathname.replace(/\/(?<=\d\/)\d+$/, '');

    history.push(newUrl);
  };

  const saveTextModerationStatus = (currentModerateStatus: string) => {
    const declineReasonName = TAB_DECLINE_FIELD[currentModerateStatus];

    setModerationStatuses({
      ...moderationStatuses,
      [currentModerateStatus]: moderationStatuses[declineReasonName]
        ? false
        : true,
    });
  };

  const saveMediaModerationStatus = (currentModerateStatus: string) => {
    setModerationStatuses({
      ...moderationStatuses,
      [currentModerateStatus]: media[currentModerateStatus],
    });
  };

  const handleNextBtn = () => {
    const currentModerateStatus = TABS[tabNumber];

    if (
      currentModerateStatus === 'document_status' ||
      currentModerateStatus === 'other_detail_status'
    ) {
      saveTextModerationStatus(currentModerateStatus);
    } else {
      saveMediaModerationStatus(currentModerateStatus);
    }

    openNextTab();
  };

  const changeTestStatus = () => {
    if (record) {
      setToggleTestStatusLoader(true);

      httpClient
        .put(`/trusted-user/${record.id}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          refresh();
          setToggleTestStatusLoader(false);
        });
    }
  };

  useEffect(() => {
    const tabNumberFromUrl = pathname.match(GET_TUB_NUMBER_REG_EXP);
    const tubNumber = tabNumberFromUrl ? Number(tabNumberFromUrl[0]) : 0;

    setTubNumber(tubNumber);
  }, [pathname]);

  return (
    <Toolbar
      style={{
        position: 'sticky',
        bottom: '0',
        marginTop: 'auto',
      }}
    >
      {tabNumber < 2 && (
        <DeclinePopup
          tabNumber={tabNumber}
          moderationStatuses={moderationStatuses}
          setModerationStatuses={setModerationStatuses}
        />
      )}

      <div style={{ marginLeft: 'auto' }}>
        {!!tabNumber && <YellowButton onClick={openPrevTab}>Prev</YellowButton>}
        {tabNumber < 5 && (
          <SuccessButton onClick={handleNextBtn}>Next</SuccessButton>
        )}
        {tabNumber === 5 && (
          <SubmitBtn
            record={record}
            moderationStatuses={moderationStatuses}
            setModerationStatuses={setModerationStatuses}
            media={media}
          />
        )}
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;

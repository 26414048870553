import React, { useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import { httpClient } from 'httpClient';

import ErrorButton from 'layout/buttons/ErrorButton';
import SuccessButton from 'layout/buttons/SuccessButton';
import { IceBreakerModerationStatus } from 'model-types';
import { EventEmitter } from 'eventEmitter';
import { EmitterEvents } from 'types/enums/EmitterEvents';

export const ModerationBtns = () => {
  const refresh = useRefresh();

  const record = useRecordContext();
  const [isModerating, setIsModerating] = useState(false);

  const handleModerate = (moderationStatus: number) => {
    setIsModerating(true);

    httpClient
      .post(`/ice-breaker/moderation/${record.id}`, {
        moderation_status: moderationStatus,
      })
      .then(() => {
        refresh();

        EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);
      })
      .finally(() => setIsModerating(false));
  };

  if (!record) return null;

  return (
    <>
      <SuccessButton
        disabled={!!record.is_deleted}
        onClick={() => handleModerate(IceBreakerModerationStatus.Approve)}
        style={{
          minWidth: '100px',
          margin: '4px',
          opacity:
            record.moderation_status === IceBreakerModerationStatus.Decline
              ? '0.3'
              : '1',
        }}
        disabled={isModerating}
      >
        Approve
      </SuccessButton>
      <ErrorButton
        disabled={!!record.is_deleted}
        onClick={() => handleModerate(IceBreakerModerationStatus.Decline)}
        style={{
          minWidth: '100px',
          margin: '4px',
          opacity:
            record.moderation_status === IceBreakerModerationStatus.Approve
              ? '0.3'
              : '1',
        }}
        disabled={isModerating}
      >
        Decline
      </ErrorButton>
    </>
  );
};

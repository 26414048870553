import React from 'react';
import { FC } from 'react';
import {
  Create,
  useRedirect,
  useNotify,
  ResourceComponentProps,
} from 'react-admin';
import IceBreakerConfigForm from './IceBreakerConfigForm';

const IceBreakerConfigCreate: FC<ResourceComponentProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Config created');
    redirect('list', props.basePath);
  };

  return (
    <Create title="Create IceBreaker config" onSuccess={onSuccess} {...props}>
      <IceBreakerConfigForm />
    </Create>
  );
};

export default IceBreakerConfigCreate;

import React from 'react';
import { Admin, Resource } from 'react-admin';

import customRoutes from 'routes';
import { authProvider } from 'authProvider';
import restProvider from 'restProvider';
import i18nProvider from 'i18nProvider';

import { Login, Layout } from 'layout';

import UserPhotoModeration from 'pages/moderation/photo/UserPhotoModeration';
import trustedUserModeration from 'pages/moderation/trustedUser';
import partner from 'pages/partner';
import partnerNetworks from 'pages/partner/network';
import users from 'pages/users/regularUsers';
import trustedUser from 'pages/users/trustedUsers';
import textCategories from 'pages/translations/textCategory';
import textSources from 'pages/translations/textSource';
import systemSettings from 'pages/systemSettings';
import supportSettings from 'pages/support/notifications';
import PhpEnumList from 'pages/docs/PhpEnumList';
import Supply from 'pages/supply/supply';
import SupplyAgents from 'pages/supply/supplyAgents';
import Notification from 'pages/supply/notifications';
import IceBreaker from 'pages/moderation/iceBreaker';
import IceBreakerAll from 'pages/iceBreakerAll';
import IceBreakerConfig from 'pages/iceBreakerConfig';
import Activity from 'pages/activity';

import 'react-toggle/style.css';
import './App.css';

const App = () => (
  <Admin
    title="Pid admin"
    dataProvider={restProvider}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    customRoutes={customRoutes}
    loginPage={Login}
    layout={Layout}
    disableTelemetry
  >
    <Resource name="photo/moderation" list={UserPhotoModeration} />
    <Resource name="trusted-user/moderation" {...trustedUserModeration} />
    <Resource name="trusted-user/re-moderation" {...trustedUserModeration} />

    <Resource name="trusted-user" {...trustedUser} />
    <Resource name="users" {...users} />

    <Resource name="partners" {...partner} />
    <Resource name="partners-networks" {...partnerNetworks} />

    <Resource name="translations/text-categories" {...textCategories} />
    <Resource name="translations/text-categories-select" />
    <Resource name="translations/text-sources" {...textSources} />

    <Resource name="system-settings" {...systemSettings} />

    <Resource name="support/ticket" {...supportSettings} />

    <Resource name="docs/enums" list={PhpEnumList} />

    <Resource name="supply" {...Supply} />
    <Resource name="supply-agent" {...SupplyAgents} />
    <Resource name="supply-notification" {...Notification} />

    <Resource name="ice-breaker/moderation" {...IceBreaker} />
    <Resource name="ice-breaker/all" {...IceBreakerAll} />
    <Resource name="ice-breaker/config" {...IceBreakerConfig} />

    <Resource name="activity" {...Activity} />
  </Admin>
);

export default App;

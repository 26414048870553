import SupplyIcon from '@material-ui/icons/Group';

import SupplyList from './SupplyList';
import SupplyEdit from './SupplyEdit';
import SupplyCreate from './SupplyCreate';

export default {
  list: SupplyList,
  edit: SupplyEdit,
  create: SupplyCreate,
  icon: SupplyIcon,
};

import React from 'react';
import { sanitizeFieldRestProps, useRecordContext } from 'react-admin';
import { SupportTicket } from 'types/SupportTicket';
import { SupportTicketReason } from 'types/enums/SupportTicket';
import { Link } from '@material-ui/core';

const LinkField = (props: any) => {
  const record = useRecordContext<SupportTicket>();

  if (!record.user?.id) return null;

  return (
    <Link
      href={`/#/users/${record.user.id}`}
      variant="body2"
      target="_blank"
      {...sanitizeFieldRestProps(props)}
    >
      {record.user.id}
    </Link>
  );
};

export default LinkField;

import React, { FC, useEffect, useState } from 'react';
import {
  Create,
  useRedirect,
  useNotify,
  ResourceComponentProps,
  SimpleForm,
  BooleanInput,
  PasswordInput,
  TextInput,
  Toolbar,
  required,
  SelectInput,
} from 'react-admin';
import { rest } from 'lodash';
import { getArrayFromLinkedList } from 'utils';
import { supplyAgentRoles } from 'consts';

const SupplyAgentsCreate: FC<ResourceComponentProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    setSuppliesSelectChoses(suppliesArray);
  };

  const onSuccess = () => {
    notify('Supply agent created');

    redirect('list', props.basePath);
  };

  useEffect(() => {
    fetchSupplies();
  }, []);

  return (
    <Create title="Create SupplyAgents" onSuccess={onSuccess} {...props}>
      <SimpleForm
        redirect="list"
        toolbar={<Toolbar alwaysEnableSaveButton />}
        {...rest}
      >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <PasswordInput source="password" validate={[required()]} />
        <BooleanInput source="is_active" defaultValue={true} />
        {!!suppliesSelectChoses && (
          <SelectInput
            label="Supply id"
            source="supply_id"
            validate={[required()]}
            choices={suppliesSelectChoses}
          />
        )}
        <SelectInput
          label="Supply role"
          source="role"
          validate={[required()]}
          choices={supplyAgentRoles}
        />
      </SimpleForm>
    </Create>
  );
};

export default SupplyAgentsCreate;

import { useEffect } from 'react';

const drawFavicon = (faviconUrl: string) => {
  const faviconElement = document.querySelector('link[rel$=icon]');

  faviconElement?.setAttribute('href', faviconUrl);
};

const drawFaviconWithBadge = (canvas, ctx, faviconUrl, activeTabCount) => {
  const faviconElement = document.querySelector('link[rel$=icon]');

  const img = document.createElement('img');
  img.src = faviconUrl;

  img.onload = () => {
    ctx.drawImage(img, 0, 0, 40, 40);

    ctx.fillStyle = 'red';
    ctx.beginPath();
    ctx.arc(25, 15, 15, 0, 2 * Math.PI);
    ctx.fill();

    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#fff';
    ctx.font = 'bold 20px Arial';
    ctx.fillText(activeTabCount, 25, 15);

    faviconElement?.setAttribute('href', canvas?.toDataURL('image/png'));
  };
};

export const useFavicon = ({
  faviconUrl,
  isModerationTab,
  activeTabCount,
}: {
  faviconUrl: string;
  isModerationTab: boolean;
  activeTabCount: number | null;
}) => {
  useEffect(() => {
    const canvas = document.createElement('canvas');
    const context = canvas?.getContext('2d');

    canvas?.setAttribute('width', '40px');
    canvas?.setAttribute('height', '40px');

    if (isModerationTab && activeTabCount) {
      drawFaviconWithBadge(canvas, context, faviconUrl, activeTabCount);
    } else {
      drawFavicon(faviconUrl);
    }
  }, [faviconUrl, isModerationTab, activeTabCount]);
};

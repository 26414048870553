import React from 'react';
import { FC } from 'react';
import {
  BooleanInput,
  Edit,
  ResourceComponentProps,
  PasswordInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  SaveButton,
  useEditController,
  minLength,
  SelectInput,
} from 'react-admin';
import { SupplyAgents } from '../../../model-types';
import { supplyAgentRoles } from 'consts';

interface PartnerProps {
  record?: SupplyAgents;
}

const SupplyAgentsTitle: FC<PartnerProps> = ({ record }) =>
  record ? <span>Supply Agents #{record.id}</span> : null;

const SupplyAgentsEdit: FC<ResourceComponentProps> = (props) => {
  const { save } = useEditController(props);

  const sanitizeData = (data: any, redirect: any) => {
    const { created_at, updated_at, ...cleanedData } = data;

    save(cleanedData, redirect);
  };

  return (
    <Edit title={<SupplyAgentsTitle />} {...props}>
      <SimpleForm
        redirect="list"
        toolbar={
          <Toolbar alwaysEnableSaveButton>
            <SaveButton />
          </Toolbar>
        }
        save={sanitizeData}
      >
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <PasswordInput
          source="password"
          validate={[required(), minLength(8)]}
        />
        <BooleanInput source="is_active" defaultValue={true} />
        <SelectInput
          label="Supply role"
          source="role"
          validate={[required()]}
          choices={supplyAgentRoles}
        />
      </SimpleForm>
    </Edit>
  );
};

export default SupplyAgentsEdit;

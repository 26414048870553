import React from 'react';
import { FC } from 'react';
import { Edit, ResourceComponentPropsWithId } from 'react-admin';
import { Partner } from '../../model-types';
import PartnerForm from './PartnerForm';

interface PartnerProps {
  record?: Partner;
}

const PartnerTitle: FC<PartnerProps> = ({ record }) =>
  record ? <span>Partner #{record.id}</span> : null;

const PartnerEdit: FC<ResourceComponentPropsWithId> = (props) => {
  return (
    <Edit title={<PartnerTitle />} {...props}>
      <PartnerForm />
    </Edit>
  );
};

export default PartnerEdit;

import { FC, useState } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import {
  BooleanField,
  DateField,
  TextField,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { TrustedUser } from 'types/trustedUser/trustedUserModeration';

import { Card, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SiteIdsSelectBlock } from './SiteIdsSelectBlock';
import ToggleActivateButton from 'layout/buttons/ToggleActivateButton';
import { httpClient } from 'httpClient';

interface Props {
  record: TrustedUser;
  userCount?: number;
  isOnline?: boolean;
}

const useStyles = makeStyles((theme) => ({
  asideCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '16.66%',
    padding: '10px',
    border: 'none',
    overflowY: 'scroll',
  },
  onlineStatus: {
    width: '10px',
    height: '10px',
    marginLeft: '5px',
    backgroundColor: 'green',
    borderRadius: '50%',
  },
  bottomContainer: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  giftAvailable: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const ShortInfoBar: FC<Props> = ({ record, userCount, isOnline }) => {
  const classes = useStyles();
  const refresh = useRefresh();

  const [isUpdatingTestStatus, setIsUpdatingTestStatus] = useState(false);

  const changeTestStatus = () => {
    if (record) {
      setIsUpdatingTestStatus(true);

      httpClient
        .put(`/trusted-user/${record.id}/test`, {
          is_test: !record.is_test,
        })
        .then(() => {
          refresh();

          setIsUpdatingTestStatus(false);
        });
    }
  };

  if (!record) return null;

  return (
    <Card className={classes.asideCard}>
      <span style={{ marginBottom: '6px' }}>
        Id: <TextField source="id" />
      </span>
      <span style={{ marginBottom: '6px' }}>
        Carthage Id:{' '}
        <TextField source="external_id" style={{ color: 'rgb(59,130,246)' }} />
        <div>
          Supply Id: <TextField source="supply.id" />
        </div>
        Supply Name:{' '}
        <TextField source="supply.name" style={{ fontWeight: 'bold' }} />
      </span>
      <span style={{ marginBottom: '6px' }}>
        Registration Date:
        <br />
        <DateField source="created_at" locales="UK" />
      </span>
      <div className={classes.giftAvailable}>
        Gift available: <BooleanField source="gift_available" />
      </div>
      <CardMedia
        component="img"
        image={record.avatar}
        alt=""
        style={{
          marginTop: '6px',
          maxHeight: '500px',
          maxWidth: '300px',
          objectFit: 'contain',
        }}
      />
      <SiteIdsSelectBlock
        userId={record.id}
        userSiteIds={record.site_ids}
        availableSupplySiteIds={record?.supply.site_ids}
      />

      <div className={classes.bottomContainer}>
        {!!userCount && <p>Moderation queue: {userCount}</p>}
        {isOnline && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            Online <p className={classes.onlineStatus} />
          </div>
        )}

        <ToggleActivateButton
          isActivate={!!record.is_test}
          handleActivate={changeTestStatus}
          handleDeactivate={changeTestStatus}
          isLoader={isUpdatingTestStatus}
          activateLabel="Deactivate is_test"
          deactivateLabel="Activate is_test"
        />
      </div>
    </Card>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Filter,
  NumberInput,
  ResourceComponentProps,
  SelectArrayInput,
  BooleanField,
  SelectInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import { getArrayFromLinkedList } from 'utils';

import CustomPagination from 'layout/Pagination';
import { moderationStatusChoices } from 'consts';
import BooleanFromNumberField from 'layout/fields/BooleanFromNumberField';

const useStyles = makeStyles((theme) => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
}));

const UserFilter = (props: any) => {
  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    setSuppliesSelectChoses(suppliesArray);
  };

  useEffect(() => {
    fetchSupplies();
  }, []);

  return (
    <Filter {...props} style={{ maxWidth: '1000px' }}>
      <NumberInput source="id" label="Id" alwaysOn style={{ width: '120px' }} />
      {!!suppliesSelectChoses && (
        <SelectArrayInput
          alwaysOn
          label="Supplies"
          source="supply_id"
          choices={suppliesSelectChoses}
          style={{ minWidth: '120px' }}
        />
      )}
      <NumberInput
        source="external_id"
        label="Carthage id"
        alwaysOn
        style={{ width: '120px' }}
      />
      <SelectInput
        alwaysOn
        label="Status"
        source="moderation_status"
        choices={moderationStatusChoices}
        style={{ minWidth: '120px' }}
      />
    </Filter>
  );
};

const TrustedUserList = (props: ResourceComponentProps) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<UserFilter />}
      perPage={30}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<CustomPagination />}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField
          source="id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          textAlign="center"
        />
        <NumberField
          label="Carthage id"
          source="external_id"
          cellClassName={classes.idCell}
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField label="Supply" source="supply.name" sortable={false} />
        <TextField label="Name" source="profile_source.name" sortable={false} />
        <TextField
          label="Lastname"
          source="profile_source.lastname"
          sortable={false}
        />
        <TextField
          label="Email"
          source="profile_source.email"
          sortable={false}
        />
        <TextField
          label="Country"
          source="city.country.name"
          sortable={false}
        />
        <BooleanFromNumberField source="is_test" />
        <BooleanField source="is_online" sortable={false} />
        <TextField label="Status" source="moderation_status" sortable={false} />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default TrustedUserList;

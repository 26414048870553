import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItemLink, useRefresh } from 'react-admin';
import { useSelector } from 'react-redux';

import ModerationIcon from '@material-ui/icons/Security';

import { AppState } from 'types';

import { EmitterEvents } from 'types/enums/EmitterEvents';
import { EventEmitter } from 'eventEmitter';
import { isGranted } from 'authProvider';

import SubMenu from './SubMenu';
import { Count } from './Count';

import { ReactComponent as PhotoModerationUserIcon } from 'assets/menu-icons/photo-moderation.svg';
import { ReactComponent as TrustedUserModerationIcon } from 'assets/menu-icons/trusted-user-moderation.svg';
import { ReactComponent as IceBreakerModerationIcon } from 'assets/menu-icons/ice-breaker-moderation.svg';
import { ReactComponent as RegularUsersIcon } from 'assets/menu-icons/person.svg';
import { ReactComponent as PartnersIcon } from 'assets/menu-icons/emoji-people.svg';
import { ReactComponent as PartnerNetworkIcon } from 'assets/menu-icons/leak-add.svg';
import { ReactComponent as TextSourcesIcon } from 'assets/menu-icons/text-fields.svg';
import { ReactComponent as TextCategoriesIcon } from 'assets/menu-icons/account-tree.svg';
import { ReactComponent as SystemSettingsIcon } from 'assets/menu-icons/settings.svg';
import { ReactComponent as WizardSettingsIcon } from 'assets/menu-icons/tune.svg';
import { ReactComponent as EnumsIcon } from 'assets/menu-icons/list.svg';
import { ReactComponent as SupplyIcon } from 'assets/menu-icons/group.svg';
import { ReactComponent as SupplyAgentsIcon } from 'assets/menu-icons/person-add.svg';
import { ReactComponent as NotificationIcon } from 'assets/menu-icons/notifications-active.svg';
import { ReactComponent as ActivityIcon } from 'assets/menu-icons/av-timer.svg';
import { ReactComponent as SupportIcon } from 'assets/menu-icons/support.svg';

import { validFavicon } from 'faviconConstants';
import { FaviconContext } from 'FaviconProvider';

import { PidvalApi } from 'api/PidvalApi';
import { getLocalStorageItem } from 'helper/localStorage';

import notificationSound from 'assets/audio/notification.mp3';
import { ModerationCounters } from 'types/ModerationCouters';

const COUNTERS_RELOAD_INTERVAL = 30 * 1000;

type MenuName =
  | 'menuPartners'
  | 'menuTds'
  | 'menuTransfer'
  | 'menuTranslations'
  | 'menuDocs'
  | 'tuModeration'
  | 'tuReModeration'
  | 'supply'
  | 'support'
  | 'settings'
  | 'wizard'
  | 'users';

const DEFAULT_MODERATION_COUNTERS = {
  icebreakersModeration: 0,
  userPhotosModeration: 0,
  trustedUsersModeration: 0,
  trustedUsersReModeration: 0,
};

const audio = new Audio(notificationSound);

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense }) => {
  const { pathname } = useLocation();

  const refresh = useRefresh();
  const { setFavicon, setCurrentCounterData } = useContext(FaviconContext);

  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const isAddedNewModerationItem = useRef<boolean>(false);
  const isInitLoad = useRef<boolean>(true);

  const [moderationCounters, setModerationCounters] =
    useState<ModerationCounters>(DEFAULT_MODERATION_COUNTERS);

  const totalModerationCount = useMemo(
    () =>
      moderationCounters.icebreakersModeration +
      moderationCounters.trustedUsersModeration +
      moderationCounters.trustedUsersReModeration +
      moderationCounters.userPhotosModeration,
    [moderationCounters],
  );

  const [activePage, setActivePage] = useState({
    menuPartners: pathname.startsWith('/partners'),
    menuTds: pathname.startsWith('/tds/marketing-flows'),
    menuTransfer: pathname.startsWith('/user-transfers/'),
    menuTranslations: pathname.startsWith('/translations/'),
    menuDocs: pathname.startsWith('/docs/'),
    supply: pathname.startsWith('/supply'),
    support: pathname.startsWith('/support'),
    tuModeration: pathname.startsWith('/trusted-user/moderation'),
    tuReModeration: pathname.startsWith('/trusted-user/re-moderation'),
    users: pathname.startsWith('/users'),
    settings:
      pathname.includes('/system-settings') ||
      pathname.includes('/wizard-finder-config') ||
      pathname.includes('/ice-breakers/config'),
    wizard: pathname.includes('/wizard'),
  });

  const isOpenSidebar = useSelector(
    (state: AppState) => state.admin.ui.sidebarOpen,
  );

  const handleToggle = useCallback((menu: MenuName) => {
    setActivePage((prevActivePage) => ({
      ...prevActivePage,
      [menu]: !prevActivePage[menu],
    }));
  }, []);

  const loadModerationCounters = useCallback(async () => {
    const countersResponse = await PidvalApi.fetchModerationCounters();

    setModerationCounters((prevCounters) => {
      const newCounters = {
        icebreakersModeration: countersResponse.icebreaker_moderation,
        userPhotosModeration: countersResponse.photo_moderation_counter,
        trustedUsersModeration: countersResponse.trusted_user_moderation,
        trustedUsersReModeration: countersResponse.trusted_user_remoderation,
      };

      if (
        (newCounters.icebreakersModeration >
          prevCounters.icebreakersModeration ||
          newCounters.userPhotosModeration >
            prevCounters.userPhotosModeration ||
          newCounters.trustedUsersModeration >
            prevCounters.trustedUsersModeration ||
          newCounters.trustedUsersReModeration >
            prevCounters.trustedUsersReModeration) &&
        !isInitLoad.current
      ) {
        isAddedNewModerationItem.current = true;
      }

      if (isInitLoad.current) isInitLoad.current = false;

      return newCounters;
    });

    timeoutId.current = setTimeout(
      loadModerationCounters,
      COUNTERS_RELOAD_INTERVAL,
    );
  }, []);

  useEffect(() => {
    if (!isAddedNewModerationItem.current || !moderationCounters) return;

    isAddedNewModerationItem.current = false;

    if (
      pathname === '/ice-breaker/moderation' ||
      pathname === '/trusted-user/moderation' ||
      pathname === '/trusted-user/re-moderation'
    ) {
      refresh();
    }

    if (pathname === '/photo/moderation/user') {
      EventEmitter.dispatch(EmitterEvents.PhotoModerationReload);
    }

    if (getLocalStorageItem('sound')) audio?.play()?.catch(() => {});
  }, [moderationCounters, pathname]);

  useEffect(() => {
    loadModerationCounters();

    EventEmitter.subscribe(EmitterEvents.ReloadModerationCounters, () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      loadModerationCounters();
    });

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      EventEmitter.unsubscribe(EmitterEvents.ReloadModerationCounters);
    };
  }, [loadModerationCounters]);

  useEffect(() => {
    setCurrentCounterData(moderationCounters);
  }, [moderationCounters]);

  useEffect(() => {
    const favicon = validFavicon(pathname);

    if (favicon && setFavicon) {
      setFavicon(favicon);
    }
  }, [pathname, setFavicon]);

  return (
    <div>
      {isGranted(['moderator', 'admin']) && (
        <SubMenu
          name="Moderation"
          isOpen={activePage.tuModeration}
          sidebarIsOpen={isOpenSidebar}
          icon={
            <Count count={totalModerationCount}>
              <ModerationIcon />
            </Count>
          }
          dense={dense}
          handleToggle={() => handleToggle('tuModeration')}
        >
          <MenuItemLink
            primaryText="User Photo"
            to={`/photo/moderation/user`}
            leftIcon={
              <Count count={moderationCounters.userPhotosModeration}>
                <PhotoModerationUserIcon />
              </Count>
            }
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Trusted user (TU)"
            to={`/trusted-user/moderation`}
            leftIcon={
              <Count count={moderationCounters.trustedUsersModeration}>
                <TrustedUserModerationIcon />
              </Count>
            }
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Re-moderation TU"
            to={`/trusted-user/re-moderation`}
            leftIcon={
              <Count count={moderationCounters.trustedUsersReModeration}>
                <TrustedUserModerationIcon />
              </Count>
            }
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Ice breaker"
            to={`/ice-breaker/moderation`}
            leftIcon={
              <Count count={moderationCounters.icebreakersModeration}>
                <IceBreakerModerationIcon />
              </Count>
            }
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['support', 'admin']) && (
        <SubMenu
          name="Support"
          isOpen={activePage.support}
          sidebarIsOpen={isOpenSidebar}
          icon={<SupportIcon />}
          dense={dense}
          handleToggle={() => handleToggle('support')}
        >
          <MenuItemLink
            primaryText="Tickets"
            to={`/support/ticket`}
            leftIcon={<NotificationIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['support', 'admin']) && (
        <SubMenu
          name="Users"
          isOpen={activePage.users}
          sidebarIsOpen={isOpenSidebar}
          icon={<RegularUsersIcon />}
          dense={dense}
          handleToggle={() => handleToggle('users')}
        >
          <MenuItemLink
            primaryText="Regular Users"
            to={`/users`}
            leftIcon={<RegularUsersIcon />}
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Trusted Users"
            to={`/trusted-user`}
            leftIcon={<TrustedUserModerationIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['admin']) && (
        <SubMenu
          name="Partners"
          isOpen={activePage.menuPartners}
          sidebarIsOpen={isOpenSidebar}
          icon={<PartnersIcon />}
          dense={dense}
          handleToggle={() => handleToggle('menuPartners')}
        >
          <MenuItemLink
            primaryText="Partners"
            to={`/partners`}
            leftIcon={<PartnersIcon />}
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Partner networks"
            to={`/partners-networks`}
            leftIcon={<PartnerNetworkIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['admin']) && (
        <SubMenu
          name="Texts"
          isOpen={activePage.menuTranslations}
          sidebarIsOpen={isOpenSidebar}
          icon={<TextSourcesIcon />}
          dense={dense}
          handleToggle={() => handleToggle('menuTranslations')}
        >
          <MenuItemLink
            primaryText="Text Sources"
            to={`/translations/text-sources`}
            leftIcon={<TextSourcesIcon />}
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Text Categories"
            to={`/translations/text-categories`}
            leftIcon={<TextCategoriesIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['admin']) && (
        <SubMenu
          name="Settings"
          isOpen={activePage.settings}
          sidebarIsOpen={isOpenSidebar}
          icon={<SystemSettingsIcon />}
          dense={dense}
          handleToggle={() => handleToggle('settings')}
        >
          <MenuItemLink
            primaryText="System settings"
            to={`/system-settings`}
            leftIcon={<SystemSettingsIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
          <MenuItemLink
            primaryText="Wizard settings"
            to={`/wizard-finder-config`}
            leftIcon={<WizardSettingsIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
          <MenuItemLink
            primaryText="Icebreaker settings"
            to={`/ice-breaker/config`}
            leftIcon={<IceBreakerModerationIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['admin']) && (
        <SubMenu
          name="Docs"
          isOpen={activePage.menuDocs}
          sidebarIsOpen={isOpenSidebar}
          icon={<EnumsIcon />} //Docs icon
          dense={dense}
          handleToggle={() => handleToggle('menuDocs')}
        >
          <MenuItemLink
            primaryText="Enums"
            to={`/docs/enums`}
            leftIcon={<EnumsIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['admin']) && (
        <SubMenu
          name="Supply"
          isOpen={activePage.supply}
          sidebarIsOpen={isOpenSidebar}
          icon={<SupplyIcon />}
          dense={dense}
          handleToggle={() => handleToggle('supply')}
        >
          <MenuItemLink
            primaryText="Supply"
            to={`/supply`}
            leftIcon={<SupplyIcon />}
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Supply agents"
            to={`/supply-agent`}
            leftIcon={<SupplyAgentsIcon />}
            dense={dense}
            onClick={onMenuClick}
          />

          <MenuItemLink
            primaryText="Notification"
            to={`/supply-notification`}
            leftIcon={<NotificationIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </SubMenu>
      )}

      {isGranted(['admin']) && (
        <>
          <MenuItemLink
            primaryText="All ice breaker"
            to={`/ice-breaker/all`}
            leftIcon={<IceBreakerModerationIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </>
      )}

      {isGranted(['admin']) && (
        <>
          <MenuItemLink
            primaryText="Activity"
            to={`/activity`}
            leftIcon={<ActivityIcon />}
            dense={dense}
            onClick={onMenuClick}
          />
        </>
      )}
    </div>
  );
};

export default Menu;

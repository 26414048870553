import React from 'react';
import { FC, useState } from 'react';
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import { costModelChoices } from 'consts';
import {
  partnerGroupChoices,
  platformChoices,
  trafficTypeChoices,
} from '../../field-choices';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  content: {
    width: '100%',
  },
  document: {
    animationName: '$fade',
    '&:after': {
      content: '"!!! FUCK UK !!!"',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%)',
      fontSize: '40px',
      zIndex: 1000,
    },
  },
  '@keyframes fade': {
    '0%': { opacity: '0' },
    '100%': { opacity: '1' },
  },
}));

export const MainConfigs: FC = () => {
  const classes = useStyles();

  const { input: countryCodeField } = useField('country_code', {
    subscription: {
      value: true,
    },
  });

  React.useEffect(() => {
    if (countryCodeField.value === 'UK') {
      window.document.body.style.animationDuration = '1s';
      window.document.body.style.animationDelay = '200';
      window.document.body.style.animationIterationCount = 'infinite';
      window.document.body.classList.add(classes.document);
    }

    return () => {
      window.document.body.classList.remove(classes.document);
    };
  }, [countryCodeField.value]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextInput source="name" className={classes.content} />
        </Grid>
        <Grid item xs={2}>
          <ReferenceInput
            label="Network"
            source="network_id"
            reference="partners-networks"
            allowEmpty={true}
            filterToQuery={(searchText: any) => ({
              name: '%' + searchText + '%',
            })}
            alwaysOn
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2}>
          <TextInput source="region" className={classes.content} />
        </Grid>
        <Grid item xs={2}>
          <TextInput source="country_code" className={classes.content} />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            source="traffic_type"
            className={classes.content}
            defaultValue={null}
            choices={trafficTypeChoices}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            source="cost_model"
            className={classes.content}
            defaultValue={null}
            choices={costModelChoices}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            source="platform"
            className={classes.content}
            defaultValue={null}
            choices={platformChoices}
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput source="manager" className={classes.content} />
        </Grid>
        <Grid item xs={2}>
          <TextInput source="direction" className={classes.content} />
        </Grid>
        <Grid item xs>
          <SelectInput
            source="partner_group"
            className={classes.content}
            defaultValue={null}
            choices={partnerGroupChoices}
          />
        </Grid>
        <Grid item xs>
          <TextInput source="partner_tag" className={classes.content} />
        </Grid>
        <Grid item xs>
          <TextInput source="spammer" className={classes.content} />
        </Grid>
        <Grid item xs>
          <BooleanInput source="autoreg" className={classes.content} />
        </Grid>
        <Grid item xs>
          <BooleanInput
            source="is_shave_invalid_email"
            label="Shave invalid email"
            className={classes.content}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <TextInput source="pixel_id" className={classes.content} />
        </Grid>
        <Grid item xs>
          <TextInput source="access_token" className={classes.content} />
        </Grid>
      </Grid>
    </div>
  );
};

import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

export const Count = ({
  count,
  children,
}: {
  count: number;
  children: ReactNode;
}) => {
  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      {children}
      {!!count && (
        <Typography
          component="span"
          variant="body2"
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '10px',
            height: '12px',
            padding: '3px',
            fontSize: '12px',
            color: '#fff',
            backgroundColor: 'red',
            borderRadius: '50%',
            transform: 'translate(-50%, -4px)',
          }}
        >
          {count}
        </Typography>
      )}
    </div>
  );
};

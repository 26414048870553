import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

import { SiteIdChoices } from 'api/PidvalApi';

const useStyles = makeStyles({
  siteIdsSelectBlock: {
    position: 'relative',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  select: {
    backgroundColor: '#fff',
  },
  chips: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
});

interface Props {
  selectedIds: number[] | null;
  siteIdChoices: SiteIdChoices[];
  onClickSelectOption: (event: any) => void;
}

export const SiteIdsSelect = ({
  selectedIds,
  siteIdChoices,
  onClickSelectOption,
}: Props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-multiple-chip">Site ids</InputLabel>
      <Select
        className={classes.select}
        multiple
        value={selectedIds}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            const selectedMirrors = siteIdChoices.filter((item) =>
              selected?.includes(item.id),
            );

            return (
              <div className={classes.chips}>
                {selectedMirrors?.map((value) => (
                  <Chip key={value.id} label={value.name} />
                ))}
              </div>
            );
          }
        }}
        onChange={onClickSelectOption}
      >
        {siteIdChoices.map((siteId) => (
          <MenuItem key={siteId.id} value={siteId.id}>
            {siteId.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

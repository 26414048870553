import React, { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import { getArrayFromLinkedList } from 'utils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

interface Transactions {
  user_id: number;
  created_at: string;
  action: string;
  amount: number;
  host: string;
  funnel: string | null;
  is_free: boolean | null;
  is_auto_refill: boolean | null;
  product_params: string | null;
}

interface Props {
  userId: number;
}

export const CreditTransactions: FC<Props> = ({ userId }) => {
  const [page, setPage] = useState(0);
  const [transactions, setTransactions] = React.useState<Transactions[]>([]);
  const [isExtend, setIsExtend] = useState(false);

  const rowsPerPage = useRef(25);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setIsExtend((state) => !state);
  };

  const fetchTransactions = async () => {
    const transactions = await getArrayFromLinkedList(
      `/users/${userId}/credit-transactions`,
    );

    setTransactions(transactions);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (isExtend && !transactions.length) {
      fetchTransactions();
    }
  }, [isExtend, transactions]);

  return (
    <Accordion onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Credit transactions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>number</TableCell>
                <TableCell>user_id</TableCell>
                <TableCell>action</TableCell>
                <TableCell>product_params</TableCell>
                <TableCell>amount</TableCell>
                <TableCell>funnel</TableCell>
                <TableCell>is_free</TableCell>
                <TableCell>is_auto_refill</TableCell>
                <TableCell>host</TableCell>
                <TableCell>timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions
                .slice(
                  page * rowsPerPage.current,
                  page * rowsPerPage.current + rowsPerPage.current,
                )
                .map((transaction, index) => (
                  <TableRow key={transaction.user_id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{transaction.user_id}</TableCell>
                    <TableCell>{transaction.action}</TableCell>
                    <TableCell>{transaction.product_params || '-'}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                    <TableCell>{transaction.funnel || '-'}</TableCell>
                    <TableCell>{String(transaction.is_free || '-')}</TableCell>
                    <TableCell>
                      {String(transaction.is_auto_refill || '-')}
                    </TableCell>
                    <TableCell>{transaction.host}</TableCell>
                    <TableCell>
                      {transaction.created_at.split('T').join(' ').slice(0, 19)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[]}
                  count={transactions.length}
                  rowsPerPage={rowsPerPage.current}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

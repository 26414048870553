import React, { FC, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import CircularProgress from '@material-ui/core/CircularProgress';
import SuccessButton from 'layout/buttons/SuccessButton';

import { httpClient } from 'httpClient';
import { EventEmitter } from 'eventEmitter';
import {
  TrustedUser,
  TrustedUserMedia,
  TrustedUserModerationStatuses,
} from 'types/trustedUser/trustedUserModeration';
import { DEFAULT_MODERATION_RESPONSE } from '../constants';
import { EmitterEvents } from 'types/enums/EmitterEvents';

interface Props {
  record: TrustedUser;
  media: { videos: TrustedUserMedia[] };
  moderationStatuses: TrustedUserModerationStatuses;
  setModerationStatuses: (newValue: TrustedUserModerationStatuses) => void;
}

export const SubmitBtn: FC<Props> = ({
  record,
  moderationStatuses,
  setModerationStatuses,
  media,
}) => {
  const { pathname } = useLocation();

  const notify = useNotify();
  const redirect = useRedirect();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkIsAllMediaModerated = () => {
    if (
      record.public_photos.length !== moderationStatuses.public_photos.length &&
      record.private_photos.length !==
        moderationStatuses.private_photos.length &&
      record.videos.length !== media.videos.length
    ) {
      notify(`Moderate all photo and video for submit`, 'error');
      return false;
    }

    const isAllPublicPhotoModerated = moderationStatuses.public_photos.find(
      (photo: TrustedUserMedia) =>
        photo.status_id !== 1 && photo.status_id !== 3,
    );
    const isAllPrivatePhotoModerated = moderationStatuses.private_photos.find(
      (photo: TrustedUserMedia) =>
        photo.status_id !== 1 && photo.status_id !== 3,
    );
    const isAllPrivatePhotoLibraryModerated =
      moderationStatuses.private_library.find(
        (photo: TrustedUserMedia) =>
          photo.status_id !== 1 && photo.status_id !== 3,
      );
    const isAllVideosModerated = media.videos.find(
      (video: TrustedUserMedia) =>
        video.status_id !== 1 && video.status_id !== 3,
    );

    if (isAllPublicPhotoModerated) {
      notify(`Moderate all public photo for submit`, 'error');
      return false;
    } else if (isAllPrivatePhotoModerated) {
      notify(`Moderate all private photo for submit`, 'error');
      return false;
    } else if (isAllPrivatePhotoLibraryModerated) {
      notify(`Moderate all private library for submit`, 'error');
      return false;
    } else if (isAllVideosModerated) {
      notify(`Moderate all video for submit`, 'error');
      return false;
    }

    return true;
  };

  const handleSubmitBtn = () => {
    if (!checkIsAllMediaModerated()) {
      return;
    }

    setIsSubmitting(true);

    const publicPhotosModerationData = moderationStatuses.public_photos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const privatePhotosModerationData = moderationStatuses.private_photos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const privateLibraryModerationData = moderationStatuses.private_library.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );
    const videosModerationData = media.videos.map(
      (mediaData: TrustedUserMedia) => {
        return {
          id: mediaData.id,
          status_id: mediaData.status_id,
          is_erotic: mediaData.is_erotic,
        };
      },
    );

    const moderationData = {
      ...moderationStatuses,
      public_photos: publicPhotosModerationData,
      private_photos: privatePhotosModerationData,
      private_library: privateLibraryModerationData,
      videos: videosModerationData,
    };

    const path = pathname.includes('re-moderation')
      ? 're-moderation'
      : 'moderation';

    httpClient
      .post(
        `/trusted-user/moderation/${record.id}`,
        { ...moderationData },
        { timeout: 60 * 1000 },
      )
      .then(() => {
        notify('User moderation saved', 'info');

        httpClient.get(`/trusted-user/${path}`).then((response) => {
          EventEmitter.dispatch(EmitterEvents.ReloadModerationCounters);

          if (response.data?.data?.[0]) {
            const redirectLink = queryString.stringifyUrl({
              url: `/trusted-user/${path}/${response.data.data[0].id}`,
            });

            redirect(redirectLink);
          } else {
            redirect(`/trusted-user/${path}`);
          }
        });

        setModerationStatuses(DEFAULT_MODERATION_RESPONSE);
      })
      .catch((error) => {
        !!error?.response?.data
          ? Object.keys(error.response.data).forEach((errorKey) =>
              notify(error.response.data[errorKey], 'error', {
                multiLine: true,
              }),
            )
          : notify(error.message, 'error');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <SuccessButton onClick={handleSubmitBtn} disabled={isSubmitting}>
      Submit
      {isSubmitting && <CircularProgress size={15} thickness={4} />}
    </SuccessButton>
  );
};

export default SubmitBtn;

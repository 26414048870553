import React from 'react';
import { Chip } from '@material-ui/core';

const IceBreakerType = ({ record }: { record?: Record<string, any> }) => {
  switch (record?.type) {
    case 1:
      return (
        <Chip label="Chat" style={{ backgroundColor: 'rgb(220, 252, 231)' }} />
      );
    case 2:
      return (
        <Chip label="Mail" style={{ backgroundColor: 'rgb(224,242,254)' }} />
      );
    case 3:
      return (
        <Chip
          label="Missed Message"
          style={{ backgroundColor: 'rgb(254,249,195)' }}
        />
      );
    case 4:
      return (
        <Chip
          label="Chat follow-up"
          style={{ backgroundColor: 'rgb(249, 220, 252)' }}
        />
      );
    case 5:
      return (
        <Chip
          label="Chat online"
          style={{ backgroundColor: 'rgb(252, 240, 220)' }}
        />
      );
    default:
      return <></>;
  }
};

export default IceBreakerType;

import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ConfirmationDialog } from './ConfirmationDialog';
import { SiteIdsSelect } from './SiteIdsSelect';
import { PidvalApi, SiteIdChoices } from 'api/PidvalApi';

const useStyles = makeStyles({
  siteIdsSelectBlock: {
    position: 'relative',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  select: {
    backgroundColor: '#fff',
  },
  chips: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
});

interface Props {
  userId: number;
  userSiteIds: number[] | null;
  availableSupplySiteIds: number[] | null;
}

export const SiteIdsSelectBlock = ({
  userId,
  userSiteIds,
  availableSupplySiteIds,
}: Props) => {
  const [siteIdChoices, setSiteIdChoices] = useState<SiteIdChoices[]>([]);
  const [isChoicesLoaded, setIsChoicesLoaded] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [newSelectedIds, setNewSelectedIds] = useState<number[]>([]);

  const classes = useStyles();

  const handleToggleConfirmationDialog = () => {
    setIsPopupShown(!isPopupShown);
  };

  const handleClickOnSelectOption = (event: any) => {
    setNewSelectedIds(event.target.value);
    handleToggleConfirmationDialog();
  };

  const handleChange = (newSelectedIds: number[]) => {
    setSelectedIds(newSelectedIds);
  };

  useEffect(() => {
    const setSiteId = async () => {
      try {
        const siteInfo = await PidvalApi.fetchMirrorShortInfo();

        setSiteIdChoices(
          siteInfo.filter((idInfo) =>
            availableSupplySiteIds?.find(
              (availableItem) => String(availableItem) === String(idInfo.id),
            ),
          ),
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (!isChoicesLoaded) {
      setIsChoicesLoaded(true);
      setSiteId();
    }
  }, [isChoicesLoaded]);

  useEffect(() => {
    if (userSiteIds) {
      setSelectedIds(userSiteIds);
    }
  }, [userSiteIds]);

  return (
    <div className={classes.siteIdsSelectBlock}>
      <SiteIdsSelect
        siteIdChoices={siteIdChoices}
        selectedIds={selectedIds}
        onClickSelectOption={handleClickOnSelectOption}
      />
      {
        <ConfirmationDialog
          isOpen={isPopupShown}
          userId={userId}
          siteIdChoices={siteIdChoices}
          selectedIds={selectedIds}
          newSelectedIds={newSelectedIds}
          onClose={handleToggleConfirmationDialog}
          onChangeSelectedIds={handleChange}
        />
      }
    </div>
  );
};

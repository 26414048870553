import React from 'react';
import { FC } from 'react';
import {
  Create,
  useRedirect,
  useNotify,
  ResourceComponentProps,
} from 'react-admin';
import SupplyForm from './SupplyForm';

const SupplyCreate: FC<ResourceComponentProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Supply created');
    redirect('list', props.basePath);
  };

  return (
    <Create title="Create Supply" onSuccess={onSuccess} {...props}>
      <SupplyForm />
    </Create>
  );
};

export default SupplyCreate;

import React, { FC, useEffect, useState } from 'react';
import {
  SimpleForm,
  SelectArrayInput,
  required,
  maxLength,
  Toolbar,
  TextInput,
  SaveButton,
  SelectInput,
  TextField,
  DeleteButton,
  useRefresh,
} from 'react-admin';
import { SupplyNotification } from '../../../model-types';
import { getArrayFromLinkedList } from 'utils';
import { CircularProgress } from '@material-ui/core';
import { notificationTypesChoses } from 'consts';

interface NotificationsProps {
  isEdit?: boolean;
  record?: SupplyNotification;
}

const NotificationsForm: FC<NotificationsProps> = ({
  isEdit,
  record,
  ...rest
}) => {
  if (!record) {
    return null;
  }

  const refresh = useRefresh();

  const [suppliesSelectChoses, setSuppliesSelectChoses] = useState<any[]>([]);
  const [defaultValue, setDefaultValue] = useState<number[] | undefined>([]);

  const fetchSupplies = async () => {
    const suppliesArray = await getArrayFromLinkedList('/supply');

    setSuppliesSelectChoses(suppliesArray);
  };

  useEffect(() => {
    const defaultValues = record.supplies?.map((supply) => supply.id);

    fetchSupplies();
    setDefaultValue(defaultValues);
  }, []);

  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={
        <Toolbar alwaysEnableSaveButton>
          {!isEdit && <SaveButton />}
          <DeleteButton
            onClick={() => {
              refresh();
            }}
          />
        </Toolbar>
      }
      style={{ height: '100%' }}
      {...rest}
    >
      {isEdit && (
        <TextField
          label="Status:"
          source="status"
          record={{
            id: record.id,
            status: record.deleted_at ? 'deleted' : 'send',
          }}
        />
      )}
      <TextInput
        source="message"
        validate={[required(), maxLength(5000)]}
        disabled={isEdit}
        multiline
        style={{ width: '100%' }}
      />
      <SelectInput
        source="type"
        defaultValue={null}
        choices={notificationTypesChoses}
        validate={[required()]}
        disabled={isEdit}
      />
      {!!suppliesSelectChoses.length ? (
        <SelectArrayInput
          label="Supply ids"
          source="supply_ids"
          choices={suppliesSelectChoses}
          defaultValue={defaultValue}
          validate={[required()]}
          disabled={isEdit}
        />
      ) : (
        <CircularProgress />
      )}
    </SimpleForm>
  );
};

export default NotificationsForm;

import React from 'react';
import { useRecordContext } from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

const BooleanFromNumberField = ({ source }: { source: string }) => {
  const record = useRecordContext();

  if (!record) return <></>;

  return record?.[source] ? (
    <span style={{ verticalAlign: 'middle', marginRight: '5px' }}>
      <DoneIcon fontSize="small" />
    </span>
  ) : (
    <span style={{ verticalAlign: 'middle', marginRight: '5px' }}>
      <ClearIcon fontSize="small" />
    </span>
  );
};
export default BooleanFromNumberField;

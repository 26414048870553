import SystemSettingsIcon from '@material-ui/icons/Settings';

import SystemSettingsList from './SystemSettingsList';
import SystemSettingsEdit from './SystemSettingsEdit';

export default {
  list: SystemSettingsList,
  edit: SystemSettingsEdit,
  icon: SystemSettingsIcon,
};

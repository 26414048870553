import {
  Datagrid,
  List,
  TextField,
  Filter,
  NumberInput,
  BooleanField,
  BooleanInput,
  TextInput,
  NumberField,
} from 'react-admin';
import CustomPagination from 'layout/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
}));

const SupplyAgentsFilter = (props: any) => (
  <Filter {...props}>
    <NumberInput source="id" alwaysOn />
    <TextInput source="name" alwaysOn />
    <TextInput source="email" />
    <BooleanInput source="is_active" />
  </Filter>
);

const SupplyAgentsList = (props: any) => {
  const classes = useStyles();

  return (
    <List
      // filters={<SupplyAgentsFilter />}
      perPage={15}
      sort={{ field: 'updated_at', order: 'DESC' }}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid optimized rowClick="edit">
        <NumberField
          source="id"
          options={{ useGrouping: false }}
          cellClassName={classes.idCell}
          sortable={false}
        />
        <NumberField
          label="Carthage agent id"
          source="external_supply_agent_id"
          cellClassName={classes.idCell}
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField source="name" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="supply.name" title="Supply name" sortable={false} />
        <TextField source="supply.site_ids" title="Site ids" sortable={false} />
        <BooleanField source="is_active" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default SupplyAgentsList;

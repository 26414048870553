import React from 'react';
import Zoom from 'react-medium-image-zoom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import GridListTile from '@material-ui/core/GridListTile';
import { Photo } from '../../../model-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    image: {
      aspectRatio: '1/1',
      objectFit: 'cover',
      objectPosition: 'center',
      width: '100%',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }),
);

export type ImageGridListProps = {
  tiles: Photo[];
};
const ImageGridList = ({ tiles }: ImageGridListProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList cols={2} rowHeight="auto">
        {tiles.map((tile) => (
          <GridListTile key={tile.profile_url}>
            <Zoom>
              <img src={tile.profile_url} className={classes.image} />
            </Zoom>
          </GridListTile>
        ))}
      </ImageList>
    </div>
  );
};
export default ImageGridList;

import React from 'react';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { httpClient } from '../../httpClient';
import { Card, CardContent, Grid, Table } from '@material-ui/core';
import { Title } from 'react-admin';
import ActivityList from './ActivityList';
import GreyButton from '../../layout/buttons/GreyButton';
import { makeStyles } from '@material-ui/core/styles';
import ActivityQueueTable from './components/ActivityQueueTable';

const useStyles = makeStyles(() => ({
  header: {
    '& h2': {
      margin: '0 15px',
      textAlign: 'center',
    },
  },
}));

const ActivityByUser = () => {
  const { userId } = useParams();
  const classes = useStyles();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  const pause = () => {
    httpClient
      .post(`/activity/switch-user/${userId}?pause=1`)
      .then((response) => setIsPaused(response.data.paused));
  };
  const unpause = () => {
    httpClient
      .post(`/activity/switch-user/${userId}?pause=0`)
      .then((response) => setIsPaused(response.data.paused));
  };

  useEffect(() => {
    httpClient.get(`/activity/user/${userId}`).then((response) => {
      setUserData(response.data);
      setLoading(false);
      setIsPaused(response.data.is_paused);
    });
  }, []);

  if (loading) return null;

  const historyProps = {
    basePath: '/activity',
    resource: 'activity',
    filter: { user_id: userId },
    filters: null,
  };

  return (
    <Card>
      <Title title="Activity by user" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <span>User: {userId}</span>
          </Grid>
          <Grid item xs={8}>
            <span>Status: {isPaused ? 'Paused' : 'Active'}</span>
            {isPaused ? (
              <GreyButton size="small" onClick={unpause}>
                Unpause
              </GreyButton>
            ) : (
              <GreyButton size="small" onClick={pause}>
                Pause
              </GreyButton>
            )}
          </Grid>
        </Grid>
        {userData.queue.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.header}>
              <h2>Queue</h2>
            </Grid>
            <Grid item xs={12}>
              <ActivityQueueTable items={userData.queue} />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.header}>
            <h2>History</h2>
          </Grid>
          <Grid item xs={12}>
            <ActivityList {...historyProps} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActivityByUser;

import { mergeTranslations } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const customMessages: any = {};
const i18nProvider = polyglotI18nProvider(
  (locale) => mergeTranslations(englishMessages, customMessages),
  'en',
);

export default i18nProvider;

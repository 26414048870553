import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  DialogContent,
  Toolbar,
  Button,
} from '@material-ui/core';
import { httpClient } from '../../httpClient';
import { useEffect, useState } from 'react';
import { Subsource } from '../../model-types';

export const SubsourcesList = (props: any) => {
  const { open, onClose, groupId } = props;

  const perPage = 49;
  const [limit, setLimit] = useState(perPage);
  const [offset, setOffset] = useState(0);
  const [resource, setResource] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(getSubsources, [limit, offset, groupId]);

  function getSubsources() {
    httpClient
      .get('/subsources', {
        params: {
          range: `[${offset},${limit}]`,
          filter: { subsource_group_id: groupId },
        },
      })
      .then((response) => {
        setResource(response.data);
        setTotal(response.headers['x-total-count']);
      });
  }

  const isPrevPageBtnVisible = offset > 0;
  const isNextPageBtnVisible = limit < total;

  const handleGoToPreviousPage = () => {
    setOffset(offset - perPage - 1);
    setLimit(limit - perPage - 1);
  };

  const handleGoToNextPage = () => {
    setOffset(offset + perPage + 1);
    setLimit(limit + perPage + 1);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>Included subsources</DialogTitle>
        <DialogContent>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Name2</TableCell>
                  <TableCell>Name3</TableCell>
                  <TableCell>Name4</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resource?.map((item: Subsource) => (
                  <TableRow key={item.subsource_id}>
                    <TableCell>{item.subsource_id}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.name2}</TableCell>
                    <TableCell>{item.name3}</TableCell>
                    <TableCell>{item.name4}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          <Toolbar>
            {isPrevPageBtnVisible && (
              <Button onClick={handleGoToPreviousPage}>Previous page</Button>
            )}
            {isNextPageBtnVisible && (
              <Button onClick={handleGoToNextPage}>Next page</Button>
            )}
          </Toolbar>
        </DialogContent>
      </Dialog>
    </>
  );
};

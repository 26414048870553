import { Pagination, Button } from 'react-admin';

const CustomPagination = (props: any) => {
  return (
    <Pagination rowsPerPageOptions={[14]} {...props}>
      <Button
        label="Prev"
        onClick={() => props.setPage(props.page - 1)}
        disabled={props.page === 1}
      />
      <Button
        label="Next"
        onClick={() => props.setPage(props.page + 1)}
        disabled={props.page === props.totalPages}
      />
    </Pagination>
  );
};

export default CustomPagination;

import React, { useCallback, useState } from 'react';
import { DateField, Toolbar } from 'react-admin';
import { httpClient } from 'httpClient';

import CustomSelect from 'layout/input/CustomSelect';
import ErrorButton from 'layout/buttons/ErrorButton';
import { logoutTimes } from '../../field-choices';

export const CustomToolbar = ({ record }: any) => {
  const [selectedLogoutTime, setSelectedLogoutTime] = useState(
    logoutTimes[0].value,
  );

  const [isForceOffline, setIsForceOffline] = useState(false);
  const [isForceLogout, setIsForceLogout] = useState(false);

  const handleLogout = () => {
    if (
      confirm(`Log out user # ${record.id} on ${selectedLogoutTime} minutes?`)
    ) {
      httpClient
        .get(`/trusted-user/${record.id}/logout?block_on=${selectedLogoutTime}`)
        .then((response) => {
          if (response.status) {
            setIsForceLogout(true);
          }
        });
    }
  };

  const handleClick = useCallback(() => {
    httpClient
      .get(`/trusted-user/${record.id}/force-offline`)
      .then((response) => {
        if (response.data.status) {
          setIsForceOffline(true);
        }
      });
  }, []);

  return (
    <Toolbar
      style={{
        position: 'sticky',
        bottom: '0',
        marginTop: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          Last login:&#160;
          <DateField
            source="last_login"
            locales="ru-RU"
            showTime
            sortable={false}
          />
        </div>
        <div>
          <p>
            Logout user on
            <CustomSelect
              choices={logoutTimes}
              onChange={setSelectedLogoutTime}
            />
            <ErrorButton
              size="small"
              onClick={handleLogout}
              disabled={isForceLogout || !record.is_online}
            >
              Log out
            </ErrorButton>
          </p>
        </div>
        <ErrorButton
          size="small"
          disabled={!record.is_online || isForceOffline}
          onClick={handleClick}
        >
          Force offline
        </ErrorButton>
      </div>
    </Toolbar>
  );
};

export default CustomToolbar;

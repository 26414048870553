import React from 'react';
import { useRecordContext } from 'react-admin';
import { SupportTicket } from 'types/SupportTicket';
import { SupportTicketReason } from 'types/enums/SupportTicket';
import Typography from '@material-ui/core/Typography';

const ReasonField = (props: any) => {
  const record = useRecordContext<SupportTicket>();

  if (!record) {
    return null;
  }

  if (record.reason === SupportTicketReason.InactiveBuyer)
    return (
      <Typography component="span" variant="body2" noWrap>
        Inactive Buyer
      </Typography>
    );

  return (
    <Typography component="span" variant="body2" noWrap>
      Transaction Error
    </Typography>
  );
};

export default ReasonField;

import { TextInput } from 'react-admin';
import React from 'react';
import { TextInputProps } from 'ra-ui-materialui/lib/input/TextInput';
import { makeStyles } from '@material-ui/core/styles';
import GreyButton from '../buttons/GreyButton';

const useStyles = makeStyles({
  clipboardButton: {
    marginTop: '15px',
    marginLeft: '10px',
  },
});

const CopyClipboardInput = ({ record, source, ...rest }: TextInputProps) => {
  const classes = useStyles();
  return (
    <div>
      <TextInput record={record} source={source} {...rest} />
      <GreyButton
        onClick={() => {
          navigator.clipboard.writeText(record[source]);
        }}
        className={classes.clipboardButton}
      >
        Copy
      </GreyButton>
    </div>
  );
};
export default CopyClipboardInput;

import React, { memo, useState } from 'react';
import { EditButton, UrlField, useRecordContext, useUpdate } from 'react-admin';
import { SupportTicket } from 'types/SupportTicket';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(() => ({
  ticketFiled: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '330px',
  },
  editBtn: {
    marginLeft: '10px',
  },
  url: {
    overflowWrap: 'anywhere',
  },
  input: {
    flex: '1',
  },
}));

const TicketField = memo((props: any) => {
  const classes = useStyles();
  const record = useRecordContext<SupportTicket>();

  const [update, state] = useUpdate<SupportTicket>();

  const [value, setValue] = useState(() => record.ticket || '');
  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    if (isEditing) {
      setIsEditing(false);

      update(
        'support/ticket',
        record.id,
        {
          ticket: value,
        },
        record,
      );

      return;
    }

    setIsEditing(true);
    setValue(record.ticket);
  };

  const handleInputChange = (newValue: string) => setValue(newValue);

  return (
    <div className={classes.ticketFiled}>
      {!isEditing && (
        <UrlField
          source="ticket"
          sortable={false}
          label="Ticket"
          target="_blank"
          className={classes.url}
        />
      )}

      {isEditing && (
        <TextareaAutosize
          className={classes.input}
          value={value}
          minRows={2}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      )}

      <EditButton
        onClick={toggleEdit}
        className={classes.editBtn}
        label={isEditing ? 'Save' : 'Edit'}
        disabled={state.loading}
      />
    </div>
  );
});

export default TicketField;

import React from 'react';
import { FC, useEffect, useState } from 'react';
import {
  SimpleForm,
  required,
  Toolbar,
  SaveButton,
  NumberInput,
  SelectInput,
  minValue,
  maxValue,
} from 'react-admin';
import { PidvalApi } from '../../api/PidvalApi';

const IceBreakerConfigForm: FC<any> = ({ record, ...rest }) => {
  const [supplyChoices, setSupplyChoices] = useState<any[]>([]);

  useEffect(() => {
    const setSupplyList = async () => {
      try {
        const choices = await PidvalApi.fetchSupplyListForConfig();

        setSupplyChoices(
          choices.map((supply: { id: string; name: string }) => ({
            id: supply.id,
            name: supply.id,
          })),
        );
      } catch (error) {
        console.error(error);
      }
    };

    setSupplyList();
  }, []);
  return (
    <SimpleForm
      redirect="list"
      record={record}
      toolbar={
        <Toolbar alwaysEnableSaveButton>
          <SaveButton />
        </Toolbar>
      }
      {...rest}
    >
      {supplyChoices && (
        <SelectInput
          label="Supply Id"
          source="supply_id"
          choices={supplyChoices}
          disabled={!!record.id}
          validate={[required()]}
        />
      )}
      <NumberInput
        source="weight"
        min={1}
        max={1000}
        defaultValue={100}
        validate={[
          required(),
          minValue(1, 'Value must be greater than 1'),
          maxValue(1000, 'Value must be less than 1000'),
        ]}
      />
    </SimpleForm>
  );
};

export default IceBreakerConfigForm;

import React from 'react';
import { FC } from 'react';
import {
  Create,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from 'react-admin';

const PartnerNetworkCreate: FC<ResourceComponentProps> = (props) => {
  return (
    <Create title="Create Partner Network" {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

export default PartnerNetworkCreate;

import React, { useCallback } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  Toolbar,
  useListContext,
  ResourceComponentProps,
  Identifier,
} from 'react-admin';
import queryString from 'query-string';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import SuccessButton from 'layout/buttons/SuccessButton';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  idCell: {
    width: '30px',
    textAlign: 'center',
  },
}));

const UserPagination = () => {
  const { ids, page, perPage, setPage, ...rest } = useListContext();
  if (page === 1 && (!ids || ids.length < perPage)) {
    return null;
  }

  return (
    <Toolbar>
      {page > 1 && (
        <SuccessButton key="prev" onClick={() => setPage(page - 1)}>
          <ChevronLeft />
          Prev
        </SuccessButton>
      )}
      {ids.length === perPage && (
        <SuccessButton key="next" onClick={() => setPage(page + 1)}>
          Next
          <ChevronRight />
        </SuccessButton>
      )}
    </Toolbar>
  );
};

const TrustedUserModerationList = (props: ResourceComponentProps) => {
  const classes = useStyles();

  const { pathname } = useLocation();

  const handleRowClick = useCallback((userId: Identifier) => {
    if (pathname.includes('re-moderation')) {
      return `/trusted-user/re-moderation/${userId}`;
    }

    return `/trusted-user/moderation/${userId}`;
  }, []);

  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<UserPagination />}
      bulkActionButtons={false}
    >
      <Datagrid optimized rowClick={handleRowClick}>
        <NumberField
          source="id"
          cellClassName={classes.idCell}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <NumberField
          label="Carthage id"
          source="external_id"
          cellClassName={classes.idCell}
          style={{ color: 'rgb(59,130,246)' }}
          options={{ useGrouping: false }}
          sortable={false}
          textAlign="center"
        />
        <TextField label="Email" source="documents.email" sortable={false} />
        <NumberField
          source="documents.date_of_birth"
          label="Date of birth"
          sortable={false}
          textAlign="center"
        />
        <TextField label="Name" source="documents.name" sortable={false} />
        <TextField
          label="City"
          source="documents.city_id.name"
          sortable={false}
        />
        <DateField
          source="created_at"
          locales="ru-RU"
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default TrustedUserModerationList;

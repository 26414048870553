import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormControl, Input, Divider, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import { SupplyIdSelect } from './SupplyIdSelect';
import { SupplyItemType } from './WizardEditConfigs';

const useStyles = makeStyles({
  listItemContent: {
    padding: '5px 0',
  },
  formControl: {
    minWidth: 150,
  },
  input: {
    padding: '0 10px',
  }
});

interface Props {
  weight: string, 
  id: string,
  supplyItemIndex: number,
  usedIdList: string[];
  isDeleteButtonDisabled: boolean;
  supplyIdList: number[];
  setSupplyItem: (args: SupplyItemType, supplyItemIndex: number) => void;
  deleteSupplyItem: (index: number) => void
}

export const SupplyItem: FC<Props> = memo(({weight, id, supplyItemIndex, usedIdList, isDeleteButtonDisabled, supplyIdList, setSupplyItem, deleteSupplyItem}) => {
  const [supplyItemWeight, setSupplyItemWeight] = useState(''); 
  const [supplyItemId, setSupplyItemId] = useState(''); 

  const classes = useStyles();

  const handleChangeSupplyItemWeight = useCallback((event) => {
    if(event.target.value < 0) {
      return;
    }

    setSupplyItemWeight(event.target.value);
  }, [setSupplyItemWeight, setSupplyItem, supplyItemId]);

  const handleBlureSupplyItemWeight = useCallback((event) => {
    setSupplyItem({supply_id: supplyItemId, weight: event.target.value}, supplyItemIndex);
  }, [setSupplyItemWeight, setSupplyItem, supplyItemId]);

  const handleChangeSupplyItemId = useCallback((value) => {
    setSupplyItemId(value);
    setSupplyItemWeight('0');
    setSupplyItem({supply_id: value, weight: '0'}, supplyItemIndex);
  }, [setSupplyItemId, setSupplyItemWeight, setSupplyItem]);
  
  const handleDeleteItem = useCallback(() => {
    deleteSupplyItem(supplyItemIndex);
  }, []);

  useEffect(() => {
    setSupplyItemWeight(weight);
  }, [setSupplyItemWeight, weight]);

  useEffect(() => {
    setSupplyItemId(id);
  }, [setSupplyItemId, id]);


  return ( 
    <li>
     <Grid container alignItems="flex-end" spacing={2} className={classes.listItemContent}>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <SupplyIdSelect currentId={supplyItemId} usedIdList={usedIdList} supplyIdList={supplyIdList} setSupplyItemId={handleChangeSupplyItemId} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <Input
                className={classes.input}
                id={`weight-${supplyItemId}`}
                type="number"
                value={supplyItemWeight}
                onChange={handleChangeSupplyItemWeight}
                onBlur={handleBlureSupplyItemWeight}
              />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
        <IconButton aria-label="delete" color="secondary" onClick={handleDeleteItem} disabled={isDeleteButtonDisabled}>
          <DeleteIcon />
        </IconButton>
        </Grid>
    </Grid>
    <Divider />
  </li>
  );
});
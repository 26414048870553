import { AuthProvider } from 'ra-core';
import { httpClient } from './httpClient';
import Cookies from 'js-cookie';

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    return httpClient
      .post('/login', { email: username, password: password })
      .then((response) => {
        localStorage.setItem('staffroles', JSON.stringify(response.data.roles));

        return response.data;
      })
      .catch((response) => {
        throw new Error(response.statusText);
      });
  },

  logout: () => {
    httpClient.get('/logout');

    return Promise.resolve();
  },

  checkError: (error) =>
    error.status !== 401 && error.status !== 403
      ? Promise.resolve()
      : Promise.reject(),

  checkAuth: () =>
    Cookies.get('staffid') ? Promise.resolve() : Promise.reject(),

  getPermissions: () => Promise.reject(),
};

const isGranted = (checkRoles: string[]) => {
  const roles = JSON.parse(localStorage.getItem('staffroles') ?? '[]');

  return (
    Array.isArray(roles) &&
    checkRoles.some((checkRole) => roles.includes(checkRole))
  );
};

export { authProvider, isGranted };
